import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Bg from '../../images/bg/page_bg01.jpg'
import CtaSection from '../../components/CtaSection/CtaSection';
import icon from '../../images/icon/magic.svg'
import SEOHelmet from '../router/SEOHelmet';
import Whatsapp from '../../components/common/Whatsapp';

const RefundPage = (props) => {

    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }
    return (
        <Fragment>
             <SEOHelmet />
            <div className='body_wrap sco_agency'>
                <Header />
                <section className="page-title cp-page-title pt-200 pos-rel bg_img" style={{ backgroundImage: `url(${Bg})` }}>
                    <div className="container">
                        <div className="page-title-wrap">
                            <div className="row mt-none-30 align-items-center">
                                <div className="col-lg-8 mt-30">
                                    <div className="page-title-box">
                                        <span className="sub-title"><img src={icon} alt="" />Refund & Return</span>
                                        <h2 className="title">Replica Refund & <br /> Return Policy <br /> </h2>
                                        <p className='privacy-text'>Thank you for choosing Replica Xerography Private Limited. We strive to deliver exceptional products and services to all our valued customers. To maintain transparency and clarify our policies, we have established the following Cancellation and Refund Policy:</p>
                                        {/* <span className="page-update_time">Updated on : December 10th, 2024</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="terms&conditions-section cp-det-bg">
                    <div className="container">
                        <div className="terms-section_inner pt-60 pb-75">
                            <div className="row">
                                <div className="col-lg-11">
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Order Placement and Acknowledgment</h2>
                                        <p>Upon placing an order with Replica Xerography Private Limited, you will receive an acknowledgment confirming the receipt of your order. This acknowledgment will contain essential details such as the order number, product(s) selected, pricing, delivery address, and any other relevant information related to your purchase.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">No Cancellation Policy</h2>
                                        <p>Once an order has been placed, it is considered final and cannot be cancelled under any circumstances. We understand that situations may arise where you might wish to modify or cancel your order; however, due to the nature of our business and our commitment to timely production and delivery, we do not offer order cancellation.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Quality Assurance</h2>
                                        <p>At Replica Xerography Private Limited, we ensure that all products undergo rigorous quality control procedures to meet the highest industry standards.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Non-Refundable Policy</h2>
                                        <p>As a company committed to delivering premium products, we do not offer refunds once an order is placed. Our non-refundable policy is in place to safeguard against potential abuse of our products and services and to maintain fairness to all our customers.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Order Confirmation</h2>
                                        <p>It is essential to thoroughly review your order before finalizing it. Once an order is submitted, we cannot make any changes, including modifications to the product(s) ordered, quantity, or delivery address. Therefore, we kindly request our customers to exercise due diligence and accuracy during the order placement process.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Damaged or Defective Products</h2>
                                        <p>In the unlikely event that your order arrives damaged or contains any manufacturing defects, please contact our customer support team within 24 hours of receipt. We will investigate the matter promptly and, if verified, offer an appropriate resolution, which may include replacement or repair of the damaged or defective product.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Customer Support</h2>
                                        <p>For any questions, concerns, or inquiries related to our products or services, please reach out to our dedicated customer support team. They are available to assist you with any assistance you may require.</p>
                                        <p>By placing an order with Replica Xerography Private Limited, you acknowledge that you have read, understood, and agreed to abide by our Cancellation and Refund Policy as outlined above. This policy is designed to maintain the highest level of customer satisfaction and uphold the integrity of our business operations.</p>
                                        <p>We value your patronage and thank you for choosing Replica Xerography Private Limited.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CtaSection cClass={'bg'}/>
            </div>
            <Footer />
            <Whatsapp />
            <Scrollbar />
        </Fragment>
    )
};
export default RefundPage;
