import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Services from '../../api/service';
import icon from '../../images/icon/search-icon.svg';
import icon1 from '../../images/icon/profile-circle.svg';

const BlogSidebar = ({ blogs }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    // Handle search submission
    const handleSearch = (e) => {
        e.preventDefault();
        if (searchTerm.trim()) {
            navigate(`/blogs?search=${encodeURIComponent(searchTerm.trim())}`);
        } else {
            navigate(`/blogs`);
        }
    };

    // Extract query parameters
    const queryParams = new URLSearchParams(location.search);
    const currentCategory = queryParams.get('category');
    const currentTag = queryParams.get('tag');

    // Extract unique categories from blogs
    const getUniqueCategories = () => {
        const categories = blogs.map(blog => blog.category);
        return [...new Set(categories)];
    };

    // Get blogs that match the current category (for related posts)
    const getRelatedBlogs = () => {
        if (currentCategory) {
            return blogs.filter(blog => blog.category === currentCategory).slice(0, 3);
        }
        return blogs.slice(0, 3);
    };

const getTags = () => {

        const predefinedTags = [
            'Brother Printer', 'Epson', 'Xerox'
        ];
        
        // const commonWordsToFilter = ['Your', 'That', 'World', 'Businesses', 'Whether', 
        //                              'Essential', 'From', 'Introduction', 'This', 'And', 
        //                              'With', 'The', 'For', 'Was', 'Are', 'Can', 'You', 'Where'];
        
        // Convert filter list to lowercase for case-insensitive comparison
        // const wordsToFilterLower = commonWordsToFilter.map(word => word.toLowerCase());
        
        // Extract words from blog content
        // const contentWords = blogs.flatMap(blog => {
        //     // Combine title and description text
        //     const text = `${blog.title} ${blog.short_description}`;
            
        //     // Extract words, clean them, and filter
        //     return text.split(/\s+/)
        //         .map(word => word.replace(/[^\w\s]/g, '').trim()) // Remove punctuation
        //         .filter(word => 
        //             word.length > 3 && // Only words longer than 3 characters
        //             !wordsToFilterLower.includes(word.toLowerCase()) // Exclude common words
        //         )
        //         .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()); // Capitalize first letter
        // });
        
        // const wordCounts = {};
        // contentWords.forEach(word => {
        //     if (word) {
        //         wordCounts[word] = (wordCounts[word] || 0) + 1;
        //     }
        // });
        
        // const extractedTags = Object.entries(wordCounts)
        //     .filter(([word, count]) => count >= 1)
        //     .sort((a, b) => b[1] - a[1])
        //     .map(entry => entry[0]);
        
        // let finalTags = [...new Set([...extractedTags, ...predefinedTags])];
        
        // finalTags = finalTags.filter(tag => 
        //     !wordsToFilterLower.includes(tag.toLowerCase())
        // );
        
        return predefinedTags.slice(0, 4);
    };

    return (
        <div className="col-lg-4 mt-30">
            <div className="sidebar">
                <div className="sidebar_widget">
                    <h3 className="sidebar_widget_title">Search</h3>
                    <form className="form-group" onSubmit={handleSearch}>
                        <input 
                            className="form-control" 
                            type="search" 
                            name="search" 
                            placeholder="Search blogs..." 
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button type="submit" className="search_icon">
                            <img src={icon} alt="Icon Search" />
                        </button>
                    </form>
                </div>
                
                <div className="sidebar_widget">
                    <h3 className="sidebar_widget_title">Related Posts</h3>
                    <ul className="recent_post_block list-unstyled">
                        {getRelatedBlogs().map((blog, Bitem) => (
                            <li className="recent_post_item" key={Bitem}>
                                <h3 className="post-title border-effect-2">
                                    <Link onClick={ClickHandler} to={`/${blog.slug}`}>
                                        {blog.title}
                                    </Link>
                                </h3>
                                <span>
                                    <img src={icon1} alt="Author" />
                                    By {blog.author || "Admin"} • {blog.date_created || "Recent"}
                                </span>
                            </li>
                        ))}
                        {getRelatedBlogs().length === 0 && (
                            <li className="recent_post_item">
                                <p>No related posts found.</p>
                            </li>
                        )}
                    </ul>
                </div>
                
                <div className="sidebar_widget">
                    <h3 className="sidebar_widget_title">Categories</h3>
                    <ul className="category_list_block list-unstyled">
                        {getUniqueCategories().map((category, index) => (
                            <li key={index} className={currentCategory === category ? "active" : ""}>
                                {console.log("encodeURIComponent(category)",encodeURIComponent(category))}
                                <Link onClick={ClickHandler} to={`/category/${encodeURIComponent(category.toLowerCase().replace(/\s+/g, '-'))}`}>
                                    <span><i className="far fa-arrow-right"></i>{category}</span>
                                    <span>({blogs.filter(blog => blog.category === category).length})</span>
                                </Link>
                            </li>
                        ))}
                        {getUniqueCategories().length === 0 && Services && Services.slice(0, 6).map((service, srv) => (
                            <li key={`service-${srv}`}>
                                <Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>
                                    <span><i className="far fa-arrow-right"></i>{service.title}</span>
                                    <span>(0{service.Id})</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                
                {/* <div className="sidebar_widget">
                    <h3 className="sidebar_widget_title">Tags</h3>
                    <ul className="tags_block list-unstyled">
                        {getTags().map((tag, index) => (
                            <li key={index} className={currentTag === tag ? "active" : ""}>
                                <Link 
                                    onClick={ClickHandler} 
                                    to={`/tag/${encodeURIComponent(tag.toLowerCase().replace(/\s+/g, '-'))}`}
                                    className={currentTag === tag ? "active-tag" : ""}
                                >
                                    {tag}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div> */}
                
                {/* Popular Posts Widget */}
                <div className="sidebar_widget">
                    <h3 className="sidebar_widget_title">Popular Posts</h3>
                    <ul className="recent_post_block list-unstyled">
                        {blogs.slice(0, 3).map((blog, Bitem) => (
                            <li className="recent_post_item" key={`popular-${Bitem}`}>
                                <div className="post-thumb">
                                    <Link onClick={ClickHandler} to={`/${blog.slug}`}>
                                        <img 
                                            src={`${process.env.PUBLIC_URL}/${blog.image_path}`} 
                                            alt={blog.title} 
                                            className="thumbnail"
                                        />
                                    </Link>
                                </div>
                                <div className="post-info">
                                    <h3 className="post-title border-effect-2">
                                        <Link onClick={ClickHandler} to={`/${blog.slug}`}>
                                            {blog.title.length > 40 ? blog.title.substring(0, 40) + '...' : blog.title}
                                        </Link>
                                    </h3>
                                    <span className="post-date">{blog.date_created}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default BlogSidebar;