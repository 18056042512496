import React from 'react'
import { Link } from 'react-router-dom'
import ContactForm from '../ContactFrom/ContactForm'
import icon1 from '../../images/icon/call-calling.svg'
import icon2 from '../../images/icon/icon-sms.svg'


const ContactSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div>
            <div className="contact pt-85">
                <div className="container">
                    <div className="row pb-130 mt-none-30">
                        <div className="col-lg-8 mt-30">
                            <div className="cs-contact-wrap cs-contact-form cd-contact-form item-contact_form">
                                <h2 className="xb-title">Send us a message</h2>
                                <p className="xb-content">Give us a chance to serve and bring magic to your brand.</p>
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-lg-4 mt-30">
                            <div className="item-contact_info">
                                <div className="xb-item--inner">
                                    <div className="xb-item--top">
                                        <h3 className="xb-item--title">Contact Info</h3>
                                        <span className="xb-item--hotline"><img src={icon1} alt="" /> +(91) 9901994734</span>
                                        <span className="xb-item--email"><img src={icon2} alt="" />  sales@replicaindia.com</span>
                                        <ul className="social_icons_block list-unstyled ul_li">
                                            <li><Link onClick={ClickHandler} to="/contact-us" aria-label="Facebook"><i className="fab fa-facebook-f"></i></Link></li>
                                            <li>
                                                <Link onClick={ClickHandler} to="/contact-us" aria-label="Twitter"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.3872 0H15.9952L10.299 7.20048L17 17H11.7545L7.64298 11.0582L2.94415 17H0.332464L6.42395 9.29688L0 0H5.37853L9.09105 5.43101L13.3872 0ZM12.4711 15.2755H13.9155L4.5917 1.63462H3.0402L12.4711 15.2755Z" fill="#0C111D" />
                                                </svg></Link>
                                            </li>
                                            <li><Link onClick={ClickHandler} to="/contact-us" aria-label="Linkedin"><i className="fab fa-linkedin-in"></i></Link></li>
                                        </ul>
                                    </div>
                                    <div className="contact-info_widget">
                                        <h3 className="xb-title"> office Address</h3>
                                        <span className="xb-location"> B-375, Outer Ring Rd, 1St Road, <br/> Peenya 1st Stage, Netaji Nagar, Peenya, <br />Bengaluru, Karnataka 560058</span>
                                    </div>
                                    {/* <div className="contact-info_widget">
                                        <h3 className="xb-title">United kingdom office</h3>
                                        <span className="xb-location">12 Buckingham Rd, example <br /> Thwaite, HG3 4 TY, UK Contact</span>
                                    </div> */}
                                    <hr className="breack-line" />
                                    <div className="contact-info_widget">
                                        <h3 className="xb-title">working hours</h3>
                                        <span className="xb-location">Mon - Sat : 9.30 am- 6.00 pm <br /> Sunday : Closed</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gmap_canvas bg-light">
                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7774.1642827865735!2d77.519067!3d13.030441!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1609048e7725%3A0x8fccd89c65c68917!2sReplica%20Xerography%20Pvt%20Ltd%20%7C%20Top%20Printer%20Rental%20%7C%20Buy%20Printer%20Online%20%7C%20Business%20Printers%20for%20Sale%20%7C%20Printers%20for%20Lease!5e0!3m2!1sen!2sin!4v1742376031319!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}

export default ContactSection;