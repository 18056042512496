import React from 'react'
import { Link } from 'react-router-dom'
import { Fade } from "react-awesome-reveal";
import icon from '../../images/icon/magic.png'
import about1 from '../../images/icon/airdrop.png'
import about2 from '../../images/icon/people.png'
import about3 from '../../images/icon/microphone.png'

const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section id="about" className="about m-lr">
            <div className="about-wrapper sec-bg pos-rel pb-80 pt-80">
                <div className="container">
                    <div className="sec-title--two text-center">
                        <Fade direction='down' triggerOnce={'false'} duration={1000} delay={9}>
                            <div className="sub-title wow fadeInDown" data-wow-duration="600ms">
                                <img src={icon} alt="" /> We Are Replica Xerography
                            </div>
                        </Fade>
                        <Fade direction='down' triggerOnce={'false'} duration={1500} delay={9}>
                            <h2 className="title wow fadeInDown" data-wow-delay="150ms" data-wow-duration="600ms">Committed to Your Printing Needs</h2>
                        </Fade>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mt-50 about-top">
                            <div className="about-left">
                                <h2 className="title fs-sm-3">Coure Value</h2>
                                <div className="about-item_box ul_li">
                                    <div className="xb-item--icon"><img src={about1} alt="" /></div>
                                    <div className="xb-item--holder">
                                        <p className="xb-item--content"><span>Innovation:</span>Constantly improving our printing solutions to meet modern business demands.</p>
                                    </div>
                                </div>
                                <div className="about-item_box ul_li">
                                    <div className="xb-item--icon"><img src={about2} alt="" /></div>
                                    <div className="xb-item--holder">
                                        <p className="xb-item--content"><span>Customer Focus:</span> Your needs drive us; we offer tailored printing services for the best results.</p>
                                    </div>
                                </div>
                                <div className="about-item_box ul_li">
                                    <div className="xb-item--icon"><img src={about3} alt="" />
                                    </div>
                                    <div className="xb-item--holder">
                                        <p className="xb-item--content"><span>Reliability:</span> Over 20 years of expertise ensuring high-quality printing solutions with excellent service.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-50 about-top">
                            <div className="about-right">
                                <div className="xb-item--holder">
                                    <h3 className="xb-item--title">Our Mission</h3>
                                    <p className="xb-item--content">Our mission is to provide seamless and efficient printing solutions, from printer sales and rentals to in-house documentation. We aim to deliver high-quality, 
                                        cost-effective, and reliable printing services to businesses and individuals alike.</p>
                                </div>
                                <div className="xb-item--holder">
                                    <h3 className="xb-item--title">Our Vision</h3>
                                    <p className="xb-item--content">Our vision is to be a trusted leader in the printing industry, 
                                        setting the benchmark for quality and customer satisfaction. We strive to empower businesses and individuals
                                         with cutting-edge printing solutions that enhance productivity and creativity.</p>
                                </div>
                            </div>
                        </div>
                        <div className="xb-btn text-center mt-90 wow fadeInUp view-more-btn view-more-mt" data-wow-duration="600ms">
                            <Fade direction='up' triggerOnce={'false'} duration={1500} delay={9}>
                                <Link onClick={ClickHandler} to="/about-us" className="thm-btn thm-btn--aso view-btn-size">Learn more about us</Link>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;