import React from 'react';
import { Fade } from "react-awesome-reveal";
import hicon from '../../images/icon/process-icon.svg'
import pImg from '../../images/process/illistration.png'
import pImg2 from '../../images/shape/pattern.png'
import sIcon1 from '../../images/icon/search01.svg'
import sIcon2 from '../../images/icon/user-icon.png'
import sIcon3 from '../../images/icon/clipboar02.svg'
import sIcon4 from '../../images/icon/medal-star.svg'

const Process = [
    {
        id:"01",
        title: 'Understanding Your Needs',
        subTitle: 'We assess your printing requirements and recommend the best solutions tailored to your business.',
        icon: sIcon1,
        direction:'one',
    },
    {
        id:"02",
        title: 'Implementing the Right Solution',
        subTitle: 'From printer rentals to managed print services, we provide seamless setup and integration.',
        icon: sIcon2,
        direction:'two',
    },
    {
        id:"03",
        title: 'Ongoing Maintenance & Support',
        subTitle: 'Our team ensures smooth operations with regular servicing, troubleshooting, and proactive monitoring.',
        icon: sIcon3,
        direction:'three',
    },
    {
        id:"04",
        title: 'Cost Optimization & Upgrades',
        subTitle: 'We help you reduce costs, improve efficiency, and upgrade to the latest technology when needed.',
        icon: sIcon4,
        direction:'four',
    },


]


const WorkProcess = (props) => {

    return (
        <section className="process pt-130 pb-100 pos-rel" style={{ backgroundColor: "#0F55DC" }}>
            <div className="container">
                <div className="sec-title--two text-center mb-90 seo-content">
                    <Fade direction='down' triggerOnce={'false'} duration={1000} delay={9}>
                        <div>
                            <div className="sub-title sub-title--strock wow fadeInDown" data-wow-duration="600ms"><img
                                src={hicon} alt="" />Our Process</div>
                        </div>
                    </Fade>
                    <Fade direction='up' triggerOnce={'false'} duration={1200} delay={9}>
                        <div>
                            <h2 className="title clr-white wow fadeInDown" data-wow-delay="150ms" data-wow-duration="600ms">How We Deliver Reliable Printing Solutions</h2>
                        </div>
                    </Fade>
                </div>
                <div className="row flex-row-reverse">
                    <div className="col-lg-6">
                        <div className="sa-process_right">
                            <Fade direction='right' triggerOnce={'false'} duration={1200} delay={9}>
                                <div>
                                    <div className="sa-process-image wow fadeInRight" data-wow-duration="600ms">
                                        <img className="updown" src={pImg} alt="" />
                                    </div>
                                </div>
                            </Fade>
                            <div className="process_shape"><img src={pImg2} alt="" /></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="sa-process_left">
                            {Process.map((process, pitem) => (
                                <div className={`process-item process-item--${process.direction}`} key={pitem}>
                                    <div className="xb-item--icon">
                                        <img src={process.icon} alt="" />
                                    </div>
                                    <h4 className="xb-item--title">{process.title}</h4>
                                    <p className="xb-item--contact">{process.subTitle}</p>
                                    <span className="xb-item--number">{process.id}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WorkProcess;