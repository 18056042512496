import React, { Fragment, useState } from "react";
import Services from "../../api/service";
import { useLocation, useParams } from "react-router-dom";
import ModalVideo from "react-modal-video";
import Header from "../../components/header/Header";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import Bg from "../../images/bg/page_bg01.jpg";
import icon from "../../images/icon/ser-01.svg";
import shape from "../../images/shape/sd-shape.png";
import sicon from "../../images/icon/check-icon.svg";
import { AnimatePresence, motion } from "framer-motion";
import SEOHelmet from "../router/SEOHelmet";
import Whatsapp from "../../components/common/Whatsapp";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const ServiceSinglePage = (props) => {
  const slug = useLocation().pathname.slice(1);
  console.log("slug_slug", slug);
  const ServiceDetails = Services?.find((item) => item.slug === slug);
  const [isOpen, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
        duration: 0.5,
      },
    },
    exit: {
      y: "-100vh",
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  const [isLoading, setIsLoading] = useState(false);

  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    company: "",
    message: "",
  });

  const [validator] = useState(
    new SimpleReactValidator({ className: "errorMessage" })
  );

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      try {
        const response = await fetch("https://replicaindia.com/contact.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(forms),
        });

        if (response.ok) {
          const data = await response.json();

          if (data.status === "success") {
            setForms({
              name: "",
              email: "",
              subject: "",
              phone: "",
              company: "",
              message: "",
            });
            setIsLoading(false);
            toast.success("Successfully we got your info.");
          } else {
            console.error("Error:", data.message);
            toast.error(data.message);
          }
        } else {
          console.error("Error:", response.statusText);
          toast.error("An error occurred. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("An error occurred. Please try again later.");
      }
    } else {
      validator.showMessages();
    }
  };

  return (
    <Fragment>
      <SEOHelmet slug={slug} />
      <div className="sco_agency">
        <Header />
        <main className="page_content service-single-page">
          <section
            className="page-title pt-200 pos-rel bg_img"
            style={{ backgroundImage: `url(${Bg})` }}
          >
            <div className="container">
              <div className="page-title-wrap sd-title-wrap">
                <div className="row mt-none-30 ">
                  <div className="col-lg-9 mt-30">
                    <div className="page-title-box">
                      <span className="sub-title">
                        <img src={icon} alt="" /> {ServiceDetails?.title}{" "}
                      </span>
                      <h1 className="pb-3">{ServiceDetails?.title}</h1>
                      <p>{ServiceDetails?.pagedescription} </p>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-30">
                    <div className="sd-right-img pos-rel">
                      {/* <img src={ServiceDetails?.sImg} alt="" className="check" /> */}
                      <div className="sd-arrow-shape">
                        {/* <img className="xbzoominzoomup check" src={ServiceDetails?.sImg} alt="" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="video pt-30 ">
            <div className="container">
              <div className="pos-rel">
                <img
                  src={ServiceDetails?.sImg}
                  alt=""
                  className="individual-service-image"
                />
                <button
                  className="popup-video btn-video"
                  onClick={() => setOpen(true)}
                >
                  {/* <img src={vImg2} alt="" /> */}
                </button>
              </div>
            </div>
          </div>
          <div className="sd-ser-content_wrap pb-50">
            <div className="container">
              <div className="sd-ser-content pr-0">
                <p className="sd-title px-60 fs-3 d-flex justify-content-center">
                  {ServiceDetails?.getAQuoteText}
                </p>
                <h4 className="sd-title fs-3 d-flex justify-content-center view-more-btn">
                  <button
                    className="btn thm-btn thm-btn--aso thm-btn--aso_blue view-btn-size"
                    onClick={() => setModalOpen(true)}
                  >
                    Get your free quote now
                  </button>
                </h4>
                <br />
                {/* Modal Form */}
                <AnimatePresence>
                  {isModalOpen && (
                    <motion.div
                      className="modal fade show d-block modal-backdrop-custom"
                      onClick={() => setModalOpen(false)}
                      variants={modalVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered modal-xs" // Changed from modal-xs to modal-sm
                        onClick={(e) => e.stopPropagation()}
                        style={{ maxWidth: "500px" }}
                      >
                        <div className="modal-content modal-content-custom border-0 shadow-sm">
                          <div className="modal-header modal-header-custom py-4">
                            <h5 className="modal-title modal-title-custom fw-bold text-white text-center w-100">
                              Get Your Free Audit
                            </h5>
                            <button
                              type="button"
                              className="btn-close modal-close-custom"
                              onClick={() => setModalOpen(false)}
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body modal-body-custom p-30 ">
                            <form onSubmit={submitHandler}>
                              <div className="row g-4">
                                <div className="col-md-6">
                                  <label className="form-label fw-semibold text-gray-800 mb-2">
                                    Your Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={forms.name}
                                    className="form-control input-custom rounded-4 bg-white py-3 px-4 h-auto"
                                    placeholder="John Doe"
                                    required
                                    onFocus={(e) =>
                                      e.target.classList.add("input-focus")
                                    }
                                    onChange={changeHandler}
                                    onBlur={(e) =>
                                      e.target.classList.remove("input-focus")
                                    }
                                  />
                                  {validator.message(
                                    "name",
                                    forms.name,
                                    "required|alpha_space"
                                  )}
                                </div>
                                <div className="col-md-6">
                                  <label className="form-label fw-semibold text-gray-800 mb-2">
                                    Your Email{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    value={forms.email}
                                    className="form-control input-custom border-0 rounded-4 bg-white  py-3 px-4 h-auto"
                                    placeholder="example@domain.com"
                                    required
                                    onChange={changeHandler}
                                    onFocus={(e) =>
                                      e.target.classList.add("input-focus")
                                    }
                                    onBlur={(e) =>
                                      e.target.classList.remove("input-focus")
                                    }
                                  />
                                  {validator.message(
                                    "email",
                                    forms.email,
                                    "required|email"
                                  )}
                                </div>
                              </div>

                              <div className="mt-4">
                                <label className="form-label fw-semibold text-gray-800 mb-2">
                                  Your Phone Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="tel"
                                  value={forms.phone}
                                  className="form-control input-custom border-0 rounded-4 bg-white  py-3 px-4 h-auto"
                                  placeholder="+1 (123) 456-7890"
                                  required
                                  onChange={changeHandler}
                                  onFocus={(e) =>
                                    e.target.classList.add("input-focus")
                                  }
                                  onBlur={(e) =>
                                    e.target.classList.remove("input-focus")
                                  }
                                />
                                {validator.message(
                                  "phone",
                                  forms.phone,
                                  "required|phone"
                                )}
                              </div>

                              <div className="mt-4">
                                <label className="form-label fw-semibold text-gray-800 mb-2">
                                  Your Organization Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  value={forms.company}
                                  className="form-control input-custom border-0 rounded-4 bg-white  py-3 px-4 h-auto"
                                  placeholder="My Company"
                                  required
                                  onChange={changeHandler}
                                  onFocus={(e) =>
                                    e.target.classList.add("input-focus")
                                  }
                                  onBlur={(e) =>
                                    e.target.classList.remove("input-focus")
                                  }
                                />
                              </div>

                              <div className="d-flex justify-content-center">
                                {/* <button
                                  type="submit"
                                  name="submit"
                                  className="btn button-custom w-auto mt-5 py-3 fw-bold thm-btn thm-btn--aso thm-btn--aso_blue"
                                  onMouseEnter={(e) =>
                                    e.target.classList.add("button-hover")
                                  }
                                  onMouseLeave={(e) =>
                                    e.target.classList.remove("button-hover")
                                  }
                                >
                                  Send Message
                                </button> */}
                                <button
                                  type="submit"
                                  name="submit"
                                  disabled={isLoading}
                                  onMouseEnter={(e) =>
                                    e.target.classList.add("button-hover")
                                  }
                                  onMouseLeave={(e) =>
                                    e.target.classList.remove("button-hover")
                                  }
                                  className={
                                    isLoading
                                      ? "loading"
                                      : "btn button-custom w-auto mt-5 py-3 fw-bold thm-btn thm-btn--aso thm-btn--aso_blue"
                                  }
                                >
                                  {isLoading ? (
                                    <>
                                      <Spin
                                        indicator={<LoadingOutlined spin />}
                                      />
                                      <span style={{ marginLeft: "8px" }}>
                                        Submitting...
                                      </span>
                                    </>
                                  ) : (
                                    <>Send Message</>
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
                {ServiceDetails?.heading1 ? (
                  <p className="sd-title mt-10 fs-3 font-title-dec">
                    {ServiceDetails?.heading1}
                  </p>
                ) : (
                  <></>
                )}
                {/* Rest of the content remains unchanged */}
                {ServiceDetails?.description1 ? (
                  <p className="sd-content sd-content-font">
                    {ServiceDetails?.description1}
                  </p>
                ) : (
                  <></>
                )}
                {ServiceDetails?.heading2 ? (
                  <p className="sd-title mt-10 fs-3 font-title-dec">
                    {ServiceDetails?.heading2}
                  </p>
                ) : (
                  <></>
                )}
                {ServiceDetails?.description2 ? (
                  <p className="sd-content sd-content-font">
                    {ServiceDetails?.description2}
                  </p>
                ) : (
                  <></>
                )}
                {ServiceDetails?.heading3 ? (
                  <p className="sd-title mt-30 fs-3 font-title-dec">
                    {ServiceDetails?.heading3}
                  </p>
                ) : (
                  <></>
                )}
                {ServiceDetails?.description3 ? (
                  <p className="text-muted mb-4">
                    {ServiceDetails?.description3}
                  </p>
                ) : (
                  <></>
                )}

                {ServiceDetails?.lists?.length > 0 && (
                  <div className="mb-4">
                    {ServiceDetails.lists.map((item, index) => (
                      <div key={index} className="mb-3">
                        <p className="d-flex align-items-center flex-wrap">
                          <span className="xb-item--title fw-bold me-2 font-title-dec">
                            <img
                              src={sicon}
                              alt=""
                              style={{ marginRight: "10px" }}
                            />
                            {item.title}
                          </span>
                        </p>
                        <p className="sd-content sd-content-font">
                          {item?.description ? item?.description : ""}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                {ServiceDetails?.heading4 ? (
                  <p className="sd-title mt-30 fs-3 font-title-dec">
                    {ServiceDetails?.heading4}
                  </p>
                ) : (
                  <></>
                )}
                {ServiceDetails?.description4 ? (
                  <p className="sd-content sd-content-font">
                    {ServiceDetails?.description4}
                  </p>
                ) : (
                  <></>
                )}
                {ServiceDetails?.heading5 ? (
                  <p className="sd-title mt-30 fs-3 font-title-dec">
                    {ServiceDetails?.heading5}
                  </p>
                ) : (
                  <></>
                )}
                {ServiceDetails?.description5 ? (
                  <p className="sd-content sd-content-font">
                    {ServiceDetails?.description5}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="sd-process_warp">
            <div className="container">
              <div className="sd-heading">
                <h2 className="sd-title">Our Process</h2>
                {ServiceDetails?.ourProcessDescription ? (
                  <p className="sd-content sd-content-font">
                    {ServiceDetails?.ourProcessDescription}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div className="sd-process_inner ul_li">
                {ServiceDetails?.process?.length > 0 && (
                  <>
                    {ServiceDetails.process.map((item, index) => (
                      <div className="sd-process-container">
                        <div key={index} className="sd-process-item">
                          <div className="xb-item--icon">
                            <img src={item.img} alt="" />
                          </div>
                          <h3 className="xb-item--title">{item.title}</h3>
                          <p className="xb-item--contact">{item.description}</p>
                          <span className="xb-item--number">
                            {(index + 1).toString().padStart(2, "0")}
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
                <div className="sd-shape">
                  <img src={shape} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="sd-service_wrap pt-115 pb-130">
            <div className="container">
              <div className="sd-ser-outcome">
                <div className="sd-heading">
                  {ServiceDetails?.whyChooseTitle ? (
                    <h2 className="sd-title">
                      {ServiceDetails?.whyChooseTitle}
                    </h2>
                  ) : (
                    <></>
                  )}
                  {ServiceDetails?.whyChooseDescription ? (
                    <p className="sd-content sd-content-font">
                      {ServiceDetails?.whyChooseDescription}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="sd-list-item ul_li justify-content-center">
                  {ServiceDetails?.chooseFirstLists?.length > 0 && (
                    <ul className="sd-ser-list list-unstyled">
                      {ServiceDetails.chooseFirstLists.map((item, index) => (
                        <li key={index}>
                          <img src={sicon} alt="" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                  {ServiceDetails?.chooseSecondLists?.length > 0 && (
                    <ul className="sd-ser-list list-unstyled">
                      {ServiceDetails.chooseSecondLists.map((item, index) => (
                        <li key={index}>
                          <img src={sicon} alt="" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <CtaSection />
      </div>
      <Footer />
      <Whatsapp />
      <Scrollbar />
    </Fragment>
  );
};

export default ServiceSinglePage;
