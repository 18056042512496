import React from 'react';
import sIcon1 from '../../images/icon/eye-icon.svg'
// import sIcon2 from '../../images/feature/ap-feature01.jpg'
// import sIcon3 from '../../images/feature/ap-feature02.jpg'
// import sIcon4 from '../../images/feature/ap-feature03.jpg'
import sIcon2 from '../../images/feature/espson.jpg'
import sIcon3 from '../../images/feature/xerox.jpg'
import sIcon4 from '../../images/feature/brother.jpg'


const WhyChoose = (props) => {

    return (
        <section className="feature pt-130">
                <div className="container">
                    <div className="sec-title--two text-center mb-60">
                        <div className="sub-title"><img src={sIcon1} alt=""/> Our Partners</div>
                        {/* <h2 className="title">Customer-Centric Approach</h2> */}
                        <h2 className="title">Authorised dealers of</h2>
                    </div>
                    <div className="row mt-none-30">
                        <div className="col-lg-4 col-md-6 mt-30">
                            <div className="ap-fea-item pos-rel">
                                <div className="xb-item--img"><img src={sIcon2} alt=""/></div>
                                <h3 className="xb-item--content">EPSON</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-30">
                            <div className="ap-fea-item pos-rel">
                                <div className="xb-item--img"><img src={sIcon3} alt=""/></div>
                                <h3 className="xb-item--content">XEROX</h3>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-30">
                            <div className="ap-fea-item pos-rel">
                                <div className="xb-item--img"><img src={sIcon4} alt=""/></div>
                                <h3 className="xb-item--content">BROTHER</h3>
                            </div>
                        </div>
                    </div>
                </div>
             </section>
    );
}

export default WhyChoose;