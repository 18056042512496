import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import replicaMeta from "../../json/replica_meta/all_pages_meta.json";

const SEOHelmet = ({ slug }) => {
  const location = useLocation();
  const [metadata, setMetadata] = useState({
    title: "Replica India",
    description: "Printer and copier solutions for businesses",
    canonical: "",
    ogTitle: "",
    ogDescription: "",
    ogImage: "",
  });

  console.log("location", metadata);
  useEffect(() => {
    if (slug) {
      const pageData = replicaMeta.find((page) => page.slug === slug);

      if (pageData) {
        setMetadata({
          title: pageData.title || "Replica India",
          description:
            pageData["meta:description"] ||
            "Printer and copier solutions for businesses",
          canonical: pageData.url || `https://replicaindia.com/${slug}`,
          ogTitle:
            pageData["property:og:title"] || pageData.title || "Replica India",
          ogDescription:
            pageData["property:og:description"] ||
            pageData["meta:description"] ||
            "Printer and copier solutions for businesses",
          ogImage: pageData["property:og:image"] || "",
        });
      }
    }
  }, [location.pathname]);


  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <link rel="canonical" href={metadata.canonical} />

      <meta property="og:title" content={metadata.ogTitle} />
      <meta property="og:description" content={metadata.ogDescription} />
      <meta property="og:url" content={metadata.canonical} />
      {metadata.ogImage && (
        <meta property="og:image" content={metadata.ogImage} />
      )}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metadata.ogTitle} />
      <meta name="twitter:description" content={metadata.ogDescription} />
      {metadata.ogImage && (
        <meta name="twitter:image" content={metadata.ogImage} />
      )}
    </Helmet>
  );
};

export default SEOHelmet;
