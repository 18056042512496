import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import BlogList from '../../components/BlogList';
import SEOHelmet from '../router/SEOHelmet';
import Whatsapp from '../../components/common/Whatsapp';


const BlogPage = (props) => {

    return (
        <Fragment>
             <SEOHelmet slug={"blogs"}/>

            <Header />
            <main className="page_content blog-page">
                <section className='pt-135'>

                <BlogList />
                </section>
            </main>
            <CtaSection />
            <Footer />
            <Whatsapp />
            <Scrollbar />
        </Fragment>
    )
};
export default BlogPage;
