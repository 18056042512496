import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Bg from '../../images/bg/page_bg01.jpg'
import CtaSection from '../../components/CtaSection/CtaSection';
import icon from '../../images/icon/magic.svg'
import SEOHelmet from '../router/SEOHelmet';
import Whatsapp from '../../components/common/Whatsapp';

const PrivacyPage = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <Fragment>
             <SEOHelmet />
            <div className='body_wrap sco_agency'>
                <Header />
                <section className="page-title cp-page-title pt-200 pos-rel bg_img" style={{ backgroundImage: `url(${Bg})` }}>
                    <div className="container">
                        <div className="page-title-wrap">
                            <div className="row mt-none-30 align-items-center">
                                <div className="col-lg-8 mt-30">
                                    <div className="page-title-box">
                                        <span className="sub-title"><img src={icon} alt="" />Privacy Policy</span>
                                        <h2 className="title">Replica Privacy Policy </h2>
                                        <p className="privacy-text">Your privacy is of utmost importance to Replica Xerography Private Limited. This Privacy Policy outlines how we collect, use, store, and protect your personal information. By accessing or using our website and services, you consent to the practices described in this Privacy Policy. Please read this document carefully before providing any personal information.</p>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 mt-30">
                                    <div className="cp-img-slide">
                                        <div className="cp-img-inner ul_li">
                                            <div className="cp-item marquee-first">
                                                <div className="xb-img"><img src={gImg1} alt="" /></div>
                                                <div className="xb-img"><img src={gImg2} alt="" /></div>
                                                <div className="xb-img"><img src={gImg3} alt="" /></div>
                                                <div className="xb-img"><img src={gImg1} alt="" /></div>
                                                <div className="xb-img"><img src={gImg2} alt="" /></div>
                                                <div className="xb-img"><img src={gImg3} alt="" /></div>
                                            </div>
                                            <div className="cp-item marquee-2">
                                                <div className="xb-img"><img src={gImg4} alt="" /></div>
                                                <div className="xb-img"><img src={gImg5} alt="" /></div>
                                                <div className="xb-img"><img src={gImg6} alt="" /></div>
                                                <div className="xb-img"><img src={gImg4} alt="" /></div>
                                                <div className="xb-img"><img src={gImg5} alt="" /></div>
                                                <div className="xb-img"><img src={gImg6} alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="terms&conditions-section cp-det-bg">
                    <div className="container">
                        <div className="terms-section_inner pt-40 pb-75">
                            <div className="row">
                                <div className="col-lg-11">
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Information We Collect</h2>
                                        <p>We may collect various types of information from you, including but not limited to</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Personal Information</h2>
                                        <p>Name, email address, postal address, phone number, and other identifying details necessary to process your orders or inquiries.</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Payment Information</h2>
                                        <p>Credit card, debit card, or other payment details required to process payments for product orders.</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Website Usage Data</h2>
                                        <p>We may collect non-personal information, such as IP address, browser type, device information, and website usage data through cookies and similar technologies to enhance your browsing experience.</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Use of Information:</h2>
                                        <ul className="privacy-details">
                                            <li>We use the collected information for the following purposes</li>
                                            <li>Process and fulfil your orders for replica xerography products.</li>
                                            <li>Provide customer support and respond to your inquiries.</li>
                                            <li>Improve our website, products, and services.</li>
                                            <li>Send promotional emails or updates related to our products and offers (you can opt-out at any time).</li>
                                            <li>Protect against fraudulent or unauthorized activities.</li>
                                        </ul>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Data Protection and Security</h2>
                                        <p>We take appropriate measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. Our website and payment gateways use SSL encryption to secure data transmission.</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Third-Party Disclosure</h2>
                                        <p>We may share your information with trusted third-party service providers to facilitate order processing, shipping, or payment processing. However, we do not sell, trade, or otherwise transfer your personal information to outside parties for marketing purposes.</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Cookies and Similar Technologies</h2>
                                        <p>We use cookies and similar technologies to enhance your browsing experience, analyze website traffic, and gather non-personal information for marketing and statistical purposes. You can adjust your browser settings to disable cookies, but this may impact certain website features.</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Links to Third-Party Sites</h2>
                                        <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of such external sites. We encourage you to review the privacy policies of these websites before providing any personal information.</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Data Retention</h2>
                                        <p>We retain your personal information for as long as necessary to fulfil the purposes for which it was collected or as required by applicable laws.</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Minors’ Privacy</h2>
                                        <p>Our website and services are not intended for individuals under the age of 18. We do not knowingly collect personal information from minors. If you are a parent or guardian and believe your child has provided us with personal information, please contact us, and we will promptly delete such data.</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Changes to Privacy Policy</h2>
                                        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. The revised version will be effective immediately upon posting on our website.</p>
                                    </div>
                                    <div className="item-details-widget">
                                        <h2 className="item_details_info_title">Contact</h2>
                                        <p>If you have any questions, concerns, or requests related to your personal information or this Privacy Policy, please contact our Data Protection Officer at Contact</p> 
                                        <p><Link onClick={ClickHandler} to="/contact-us" className="details-link">Click here</Link> to contact us regarding this Terms & Conditions or other related issues. <br/>
                                        You can also send us an e-mail on: <a href=" sales@replicaindia.com" className='fw-bold'> sales@replicaindia.com</a>
                                        <br/> You can contact on <span className='fw-bold'>(+91) 9901994734.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CtaSection cClassNamclassName={'bg'} />
            </div>
            <Footer />
            <Whatsapp />
            <Scrollbar />
        </Fragment>
    )
};
export default PrivacyPage;
