import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../../images/shape/trangle-shape.png'
import icon13 from '../../images/icon/sms-white-icon01.svg'
import logo from '../../images/logo/replica-logo.png'
import MobileMenu from '../MobileMenu/MobileMenu'
import ServiceSinglePage from '../../main-component/ServiceSinglePage/ServiceSinglePage';

const Header = (props) => {

    const [mobailActive, setMobailState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (

        <div id="xb-header-area" className="header-area header-style-two header-transparent">
            <div className="header-top">
                <span>Get 15% OFF on all AMC plans until August 31! Keep your printers running smoothly with Replica. 🚀</span>
                <div className="header-shape">
                    <div className="shape shape--one"><img src={icon1} alt="" /></div>
                    <div className="shape shape--two"><img src={icon1} alt="" /></div>
                </div>
            </div>
            <div className={`xb-header stricky  ${isSticky ? 'stricked-menu stricky-fixed' : ''}`}>
                <div className="container">
                    <div className="header__wrap ul_li_between">
                        <div className="header-logo">
                            <Link onClick={ClickHandler} to="/"><img src={logo} style={{width:'250px'}} alt="" /></Link>
                        </div>
                        <div className="main-menu__wrap ul_li navbar navbar-expand-xl">
                            <nav className="main-menu collapse navbar-collapse">
                                <ul>
                                    <li>
                                        <Link onClick={ClickHandler} to="/"><span>Home</span></Link>
                                        
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/about-us"><span>About Us</span></Link>

                                    </li>
                                    <li className="menu-item-has-children ">
                                        <Link onClick={ClickHandler} to="/solutions"><span>Solutions</span></Link>
                                        <ul className="submenu">
                                        <li><Link onClick={ClickHandler} to="/printer-rental-service"><span>Rental Solutions</span></Link></li>
                                        <li><Link onClick={ClickHandler} to="/managed-print-services"><span>Print Management Software Solutions</span></Link></li>
                                        <li><Link onClick={ClickHandler} to="/in-house-documentation"><span>In-House Documentation</span></Link></li>
                                        <li><Link onClick={ClickHandler} to="/annual-maintenance-contract"><span>AMC - Annual Maintenance Contract</span></Link></li>
                                        <li><Link onClick={ClickHandler} to="/fsma-full-service-and-maintenance-agreement"><span>FSMA - Full Services and Maintenance Agreement</span></Link></li>
                                           
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <Link onClick={ClickHandler} to="#"><span>Partners</span></Link>
                                        <ul className="submenu">
                                            <li><Link onClick={ClickHandler} to="/partners/brother"><span>Brother</span></Link></li>
                                            <li><Link onClick={ClickHandler} to="/partners/epson"><span>Epson</span></Link></li>
                                            <li><Link onClick={ClickHandler} to="/partners/xerox"><span>Xerox</span></Link></li>

                                        </ul>
                                    </li>
                                    <li className="">
                                        <Link onClick={ClickHandler} to="/blogs"><span>Resources</span></Link>
                                        {/* <ul className="submenu">
                                            <li><Link onClick={ClickHandler} to="/blogs"><span>Blog</span></Link></li>
                                            <li><Link onClick={ClickHandler} to="/blog-single/AI-in-Neuroimaging-IXICOs-Cloud-Transformation"><span>Blog Details</span></Link></li>
                                        </ul> */}
                                    </li>
                                    <li><Link onClick={ClickHandler} to="/contact-us"><span>Contact</span></Link></li>
                                    <li><Link onClick={ClickHandler} to="/careers"><span>Careers</span></Link></li>
                                    {/* <li><Link onClick={ClickHandler} to="/contact-us"><span>Get Free Audit</span></Link></li> */}
                                </ul>
                            </nav>
                            <div className="xb-header-wrap">
                                <div className={`xb-header-menu ${mobailActive ? "active" : ""}`}>
                                    <div className="xb-header-menu-scroll lenis lenis-smooth">
                                        <div className="xb-menu-close xb-hide-xl xb-close" onClick={() => setMobailState(!mobailActive)}></div>
                                        <div className="xb-logo-mobile xb-hide-xl">
                                            <Link onClick={ClickHandler} to="/" rel="home"><img src={logo}
                                                    alt=""/></Link>
                                        </div>
                                        <nav className="xb-header-nav">
                                            <MobileMenu/>
                                        </nav>
                                    </div>
                                </div>
                                <div className="xb-header-menu-backdrop"></div>
                            </div>
                        </div>
                        <div className="header-bar-mobile side-menu d-xl-none">
                            <button className="xb-nav-mobile" onClick={() => setMobailState(!mobailActive)}>
                                <i className="far fa-bars"></i>
                            </button>
                        </div>
                        <div className="header-contact d-none d-md-block">
                            <Link onClick={ClickHandler} to="/contact-us" className="thm-btn thm-btn--aso thm-btn--header-black">Get Free Audit
                                <img src={icon13} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Header;