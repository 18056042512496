import React, { useCallback, useState, useRef, useEffect } from "react";
import blogs from "../../json/blogs.json";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import BlogSidebar from "../BlogSidebar";
import icon1 from "../../images/icon/icon_calendar.svg";
import icon2 from "../../images/icon/user-black.svg";
import icon4 from "../../images/icon/icon_eye.svg";
import icon5 from "../../images/icon/icon_link.svg";
import icon7 from "../../images/icon/icon-left.png";
import icon8 from "../../images/icon/profile-circle.svg";
import icon9 from "../../images/icon/icon-right.png";
import { Helmet } from "react-helmet-async";

const BlogSingle = (props) => {
  const { slug } = useParams();
  const [copiedTrue, setCopiedTrue] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [readingTime, setReadingTime] = useState("0:00");
  const [currentTime, setCurrentTime] = useState("0:00");
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [nextBlog, setNextBlog] = useState(null);
  const [prevBlog, setPrevBlog] = useState(null);
  const [blogKeywords, setBlogKeywords] = useState([]);
  const speechSynthesisRef = useRef(null);
  const blogTextRef = useRef("");
  const timerIntervalRef = useRef(null);
  const elapsedSecondsRef = useRef(0);
  const totalSecondsRef = useRef(0);

  const BlogDetails = blogs?.find((item) => item.slug === slug);

  // Function to extract plain text content from blog for text-to-speech
  const extractPlainText = useCallback((content) => {
    if (!content || !Array.isArray(content)) return "";

    let plainText = "";

    content.forEach((item) => {
      switch (item.type) {
        case "paragraph":
          plainText += `${stripHtml(item.text)} `;
          break;
        case "heading":
          plainText += `${stripHtml(item.text)}. `;
          break;
        case "blockquote":
          plainText += `Quote: ${stripHtml(item.text)} `;
          break;
        case "list":
          item.items.forEach((listItem) => {
            if (listItem.title) {
              plainText += `${listItem.title}. `;
            }
            if (listItem.text) {
              plainText += `${stripHtml(
                removeDuplicateTitleFromText(listItem.title, listItem.text)
              )} `;
            }
          });
          break;
        case "table":
          if (item.headers) {
            plainText += `${item.headers.join(", ")}. `;
          }
          if (item.rows) {
            item.rows.forEach((row) => {
              plainText += `${row.join(", ")}. `;
            });
          }
          break;
        case "code_block":
          plainText += "Code block omitted from audio. ";
          break;
        default:
          break;
      }
    });

    return plainText;
  }, []);

  // Strip HTML tags from text
  const stripHtml = (text) => {
    if (!text) return "";
    return text
      .replace(/\*\*(.*?)\*\*/g, "$1")
      .replace(/\[(.*?)\]\((.*?)\)/g, "$1");
  };

  // Calculate reading time based on words per minute
  const calculateReadingTime = useCallback((text) => {
    const wordsPerMinute = 150; // Average reading speed
    const wordCount = text.split(/\s+/).length;
    const minutes = Math.floor(wordCount / wordsPerMinute);
    const seconds = Math.floor(
      ((wordCount % wordsPerMinute) / wordsPerMinute) * 60
    );

    totalSecondsRef.current = minutes * 60 + seconds;

    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  }, []);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  // Start the timer
  const startTimer = () => {
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
    }

    timerIntervalRef.current = setInterval(() => {
      elapsedSecondsRef.current += 1;
      setCurrentTime(formatTime(elapsedSecondsRef.current));

      if (elapsedSecondsRef.current >= totalSecondsRef.current) {
        clearInterval(timerIntervalRef.current);
        setIsPlaying(false);
      }
    }, 1000);
  };

  const stopTimer = () => {
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }
  };

  const resetTimer = () => {
    elapsedSecondsRef.current = 0;
    setCurrentTime("0:00");
  };

  const findRelatedBlogs = useCallback((currentBlog) => {
    if (!currentBlog || !blogs || blogs.length === 0) return [];

    const sameCategoryBlogs = blogs.filter(
      (blog) =>
        blog.category === currentBlog.category && blog.slug !== currentBlog.slug
    );

    if (sameCategoryBlogs.length >= 3) {
      return sameCategoryBlogs.slice(0, 3);
    }
    const otherBlogs = blogs.filter(
      (blog) =>
        blog.category !== currentBlog.category && blog.slug !== currentBlog.slug
    );

    return [...sameCategoryBlogs, ...otherBlogs].slice(0, 3);
  }, []);

  // Find next and previous blogs
  const findNextPrevBlogs = useCallback((currentBlog) => {
    if (!currentBlog || !blogs || blogs.length <= 1)
      return { next: null, prev: null };

    // Find the index of the current blog
    const currentIndex = blogs.findIndex(
      (blog) => blog.slug === currentBlog.slug
    );
    if (currentIndex === -1) return { next: null, prev: null };

    // Get next and previous blogs
    const nextBlog =
      currentIndex < blogs.length - 1 ? blogs[currentIndex + 1] : null;
    const prevBlog = currentIndex > 0 ? blogs[currentIndex - 1] : null;

    return { next: nextBlog, prev: prevBlog };
  }, []);

  const generateKeywords = useCallback((blog) => {
    if (!blog) return ["Technology", "Digital", "Innovation"];

    // Try to use meta keywords if available
    if (blog.meta_keywords && blog.meta_keywords !== "No keywords available") {
      const metaKeywords = blog.meta_keywords
        .split(",")
        .map((k) => k.trim())
        .filter((k) => k);
      if (metaKeywords.length >= 3) {
        return metaKeywords.slice(0, 3);
      }
    }

    let contentText = blog.title + " " + (blog.short_description || "");
    if (blog.content && Array.isArray(blog.content)) {
      blog.content.forEach((item) => {
        if (item.text) contentText += " " + item.text;
        if (item.items) {
          item.items.forEach((subItem) => {
            if (subItem.text) contentText += " " + subItem.text;
            if (subItem.title) contentText += " " + subItem.title;
          });
        }
      });
    }

    return [""];
  }, []);

  useEffect(() => {
    if (BlogDetails?.content) {
      const plainText = extractPlainText(BlogDetails.content);
      blogTextRef.current = plainText;

      const time = calculateReadingTime(plainText);
      setReadingTime(time);

      const related = findRelatedBlogs(BlogDetails);
      setRelatedBlogs(related);

      const { next, prev } = findNextPrevBlogs(BlogDetails);
      setNextBlog(next);
      setPrevBlog(prev);

      const keywords = generateKeywords(BlogDetails);
      setBlogKeywords(keywords);
    }

    return () => {
      if (speechSynthesisRef.current) {
        window.speechSynthesis.cancel();
      }
      stopTimer();
    };
  }, [
    BlogDetails,
    calculateReadingTime,
    extractPlainText,
    findRelatedBlogs,
    findNextPrevBlogs,
    generateKeywords,
  ]);

  const handlePlayPause = () => {
    if (!isPlaying) {
      if (window.speechSynthesis) {
        if (
          elapsedSecondsRef.current === 0 ||
          elapsedSecondsRef.current >= totalSecondsRef.current
        ) {
          resetTimer();
        }

        const utterance = new SpeechSynthesisUtterance(blogTextRef.current);

        const voices = window.speechSynthesis.getVoices();
        const englishVoice = voices.find((voice) => voice.lang.includes("en"));
        if (englishVoice) {
          utterance.voice = englishVoice;
        }

        utterance.onend = () => {
          stopTimer();
          setIsPlaying(false);
          setCurrentTime(readingTime);
          elapsedSecondsRef.current = totalSecondsRef.current;
        };

        utterance.onpause = () => {
          stopTimer();
          setIsPlaying(false);
        };

        utterance.onresume = () => {
          startTimer();
          setIsPlaying(true);
        };

        speechSynthesisRef.current = utterance;
        window.speechSynthesis.speak(utterance);
        setIsPlaying(true);

        startTimer();
      }
    } else {
      if (window.speechSynthesis && speechSynthesisRef.current) {
        window.speechSynthesis.cancel();
        speechSynthesisRef.current = null;
        stopTimer();
        setIsPlaying(false);
      }
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && isPlaying) {
        stopTimer();
      } else if (!document.hidden && isPlaying) {
        startTimer();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isPlaying]);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const CopyCurrentUrl = useCallback(() => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setCopiedTrue(true);

      setTimeout(() => {
        setCopiedTrue(false);
      }, 2000);
    });
  }, []);

  const formatText = (text) => {
    if (!text) return "";

    const boldFormatted = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

    const linkFormatted = boldFormatted.replace(
      /\[(.*?)\]\((.*?)\)/g,
      '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
    );

    return linkFormatted;
  };

  const removeDuplicateTitleFromText = (title, text) => {
    if (!title || !text) return text || "";

    const newTitle = `**${title} **`;

    if (text.startsWith(newTitle)) {
      return text.slice(newTitle.length);
    }

    return text;
  };

  const renderContent = (content) => {
    if (!content || !Array.isArray(content)) {
      return <p>No content available</p>;
    }

    return content.map((item, index) => {
      switch (item.type) {
        case "paragraph":
          return (
            <p
              key={index}
              className="mb-4"
              dangerouslySetInnerHTML={{ __html: formatText(item.text) }}
            />
          );

        case "heading":
          const HeadingTag = item.level || "h3";
          return (
            <HeadingTag
              key={index}
              className="item_details_info_heading mb-4"
              dangerouslySetInnerHTML={{ __html: formatText(item.text) }}
            />
          );

        case "blockquote":
          return (
            <blockquote
              key={index}
              className="blockquote mb-4"
              dangerouslySetInnerHTML={{ __html: formatText(item.text) }}
            />
          );

        case "list":
          if (item.list_type === "ul") {
            return (
              <ul key={index} className="iconlist_block mb-4">
                {item.items.map((listItem, itemIndex) => (
                  <li key={itemIndex}>
                    {listItem.title && (
                      <strong className="d-block">{listItem.title}</strong>
                    )}

                    <span
                      className="iconlist_text"
                      dangerouslySetInnerHTML={{
                        __html: formatText(
                          removeDuplicateTitleFromText(
                            listItem.title,
                            listItem.text
                          )
                        ),
                      }}
                    />
                  </li>
                ))}
              </ul>
            );
          } else {
            return (
              <ol
                key={index}
                className="iconlist_block numlist_block list-unstyled mb-4"
              >
                {item.items.map((listItem, itemIndex) => (
                  <li key={itemIndex}>
                    {listItem.title && (
                      <strong className="d-block">{listItem.title}</strong>
                    )}
                    {listItem.text && (
                      <span
                        className="iconlist_text"
                        dangerouslySetInnerHTML={{
                          __html: formatText(
                            removeDuplicateTitleFromText(
                              listItem.title,
                              listItem.text
                            )
                          ),
                        }}
                      />
                    )}
                  </li>
                ))}
              </ol>
            );
          }

        case "table":
          return (
            <div key={index} className="table-responsive mb-4">
              <table className="table">
                {item.headers && item.headers.length > 0 && (
                  <thead>
                    <tr>
                      {item.headers.map((header, headerIndex) => (
                        <th key={headerIndex}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                )}
                <tbody>
                  {item.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          dangerouslySetInnerHTML={{ __html: formatText(cell) }}
                        />
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );

        case "code_block":
          return (
            <pre key={index} className="code-block mb-4">
              <code>{item.text}</code>
            </pre>
          );

        default:
          return null;
      }
    });
  };

  if (!BlogDetails) {
    return (
      <div className="container py-5">
        <h2>Blog post not found</h2>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content={BlogDetails?.meta_title || ""} />
        <meta
          property="og:description"
          content={BlogDetails?.meta_description || ""}
        />
        <meta property="og:url" content={BlogDetails?.canonical_url || ""} />
        <meta property="og:image" content={BlogDetails?.og_image || ""} />
        <link rel="canonical" href={BlogDetails?.canonical_url || ""} />
        <title>{BlogDetails?.meta_title || ""}</title>

        <meta name="description" content={BlogDetails?.og_description || ""} />
      </Helmet>
      <section className="blog_details_section pt-70">
        <div className="container">
          <div className="item-details_image pos-rel mb-80">
            <img
              src={`${process.env.PUBLIC_URL}/${BlogDetails.image_path}`}
              alt={BlogDetails.image_alt}
            />
          </div>
          <div className="item_details_content pb-80">
            <ul className="post_meta ul_li list-unstyled">
              <li>
                <Link onClick={ClickHandler} to={""}>
                  <span className="meta_label1">{BlogDetails.category}</span>
                </Link>
              </li>
              <li>
                <Link onClick={ClickHandler} to={""}>
                  <span className="meta_icon">
                    <img src={icon1} alt="Icon Calendar" />
                  </span>
                  <span className="meta_label">
                    Last Update: {BlogDetails.date_created}
                  </span>
                </Link>
              </li>
            </ul>
            <h2 className="item_details_heading">{BlogDetails.title}</h2>
            {BlogDetails.short_description && (
              <p className="mb-40">{BlogDetails.short_description}</p>
            )}
            <div className="row">
              <div className="col-md-6">
                <ul className="post_meta list-unstyled ul_li">
                  <li>
                    <Link onClick={ClickHandler} to={""}>
                      <span className="meta_icon">
                        <img src={icon2} alt="Icon User" />
                      </span>
                      <span className="meta_label">{"Replicaindia"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={""}>
                      <span className="meta_icon">
                        <img src={icon4} alt="Icon Eye" />
                      </span>
                      <span className="meta_label">20k Views</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="post_meta list-unstyled ul_li justify-content-md-end">
                  <li>
                    <Link onClick={CopyCurrentUrl} to={""}>
                      <span className="meta_icon">
                        <img src={icon5} alt="Icon Link" />
                      </span>
                      <span className="meta_label">
                        {copiedTrue ? "Copied" : "Copy Link"}
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <hr className="mt-5" />
          </div>
          <div className="row mt-none-30 g-0 align-items-start">
            <div className="col-lg-8 mt-30">
              <div className="blog_details_content">
                <div className="post_audio">
                  <button
                    className={`audio_play_btn ${isPlaying ? "playing" : ""}`}
                    type="button"
                    onClick={handlePlayPause}
                  >
                    <i
                      className={`fas ${isPlaying ? "fa-pause" : "fa-play"}`}
                    ></i>
                    <span>{isPlaying ? currentTime : readingTime}</span>
                    <span>
                      {isPlaying
                        ? "Listening to this article..."
                        : "Listen to this article!"}
                    </span>
                  </button>
                </div>
                <div className="blog-content-wrapper">
                  {renderContent(BlogDetails.content)}
                </div>
                <hr className="mb-50" />
                <div className="row">
                  <div className="col-md-6">
                    <ul className="tags_block list-unstyled">
                      {blogKeywords && blogKeywords?.[0]?.length
                        ? blogKeywords?.map((keyword, index) => (
                            <li key={index}>
                              <Link onClick={ClickHandler} to={"/blog"}>
                                {keyword}
                              </Link>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="social_icons_block list-unstyled ul_li justify-content-md-end">
                      <li>
                        <Link aria-label="Facebook" to="/blog">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link aria-label="Twitter" to="/blog">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link aria-label="Linkedin" to="/blog">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                      <li>
                        <Link aria-label="Instagram" to="/blog">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link aria-label="Social Share" to="/blog">
                          <i className="fas fa-share-alt"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="other_post_nav ul_li_between"
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "nowrap",
                  }}
                >
                  {prevBlog ? (
                    <Link
                      onClick={ClickHandler}
                      to={`/${prevBlog.slug}`}
                      className="post-nav-item col-md-6"
                    >
                      <div className="xb-item--arrow pos-rel">
                        <img src={icon7} alt="" />
                        <span></span>
                      </div>
                      <div className="xb-item--holder">
                        <h3 className="xb-item--title">
                          {prevBlog.title.length > 60
                            ? prevBlog.title.substring(0, 60) + "..."
                            : prevBlog.title}
                        </h3>
                        <span className="xb-item--text">
                          <img src={icon8} alt="" />
                          {prevBlog.author || "Replicaindia"}
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <div className="post-nav-placeholder"></div>
                  )}

                  {/* <Link onClick={ClickHandler} to={"/blog"} className="xb-bar col-md-1">
                  <i className="fas fa-th-large"></i>
                </Link> */}

                  {nextBlog ? (
                    <Link
                      onClick={ClickHandler}
                      to={`/${nextBlog.slug}`}
                      className="post-nav-item col-md-6"
                    >
                      <div className="xb-item--holder">
                        <h3 className="xb-item--title">
                          {nextBlog.title.length > 60
                            ? nextBlog.title.substring(0, 60) + "..."
                            : nextBlog.title}
                        </h3>
                        <span className="xb-item--text">
                          <img src={icon8} alt="" />
                          {nextBlog.author || "Replicaindia"}
                        </span>
                      </div>
                      <div className="xb-item--arrow pos-rel">
                        <img src={icon9} alt="" />
                        <span></span>
                      </div>
                    </Link>
                  ) : (
                    <div className="post-nav-placeholder"></div>
                  )}
                </div>
                <div className="item_details-newslatter">
                  <div className="xb-item--holder ul_li_between align-items-start">
                    <div className="xb-item-heading_info">
                      <h3 className="item_details_info_heading">
                        Subscribe to our updates
                      </h3>
                      <p>
                        Stay up to date! Get all of our resources and news{" "}
                        <br /> delivered straight to your inbox.
                      </p>
                    </div>
                    <span className="xb-item--bell-icon">
                      <i className="fas fa-bell"></i>
                    </span>
                  </div>
                  <div className="xb-item--item-input_field pos-rel">
                    <input type="email" placeholder="sales@replicaindia.com" />
                    <button type="submit">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
            <BlogSidebar blogs={blogs} />
          </div>
          <div className="related-blog pt-130 pb-130">
            <h2 className="related-blog-title">Browse related blog</h2>
            <div className="row mt-none-30">
              {relatedBlogs.map((blog, Bitem) => (
                <div className="col-lg-4 col-md-6 mt-30" key={Bitem}>
                  <div className="blog-details_wrap">
                    <div className="blog-details_item">
                      <div className="xb-item--img">
                        <Link onClick={ClickHandler} to={`/${blog.slug}`}>
                          <img
                            src={
                              `${process.env.PUBLIC_URL}/${blog.image_path}` ||
                              blog.screens
                            }
                            alt={blog.image_alt}
                          />
                        </Link>
                      </div>
                      <div className="xb-item--holder">
                        <div className="xb-item--meta ul_li">
                          <span className="xb-item--meta_label1">
                            {blog.category}
                          </span>
                          <span className="xb-item--meta_label">
                            By {blog.author || "Replicaindia"}
                          </span>
                        </div>
                        <h3 className="item_details_info_heading border-effect">
                          <Link onClick={ClickHandler} to={`/${blog.slug}`}>
                            {blog.title}
                          </Link>
                        </h3>
                        <Link
                          onClick={ClickHandler}
                          to={`/${blog.slug}`}
                          className="xb-item--det-btn"
                        >
                          Read more <i className="far fa-long-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogSingle;
