import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

const ContactForm = () => {
  const location = useLocation();
  const productDetails = location.state?.productDetails;
  const [isLoading, setIsLoading] = useState(false);

  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    company: "",
    message: "",
  });

  const [validator] = useState(
    new SimpleReactValidator({ className: "errorMessage" })
  );

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      try {
        const response = await fetch("https://replicaindia.com/contact.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(forms),
        });

        if (response.ok) {
          const data = await response.json();

          if (data.status === "success") {
            setForms({
              name: "",
              email: "",
              subject: "",
              phone: "",
              company: "",
              message: "",
            });
            setIsLoading(false);
            toast.success("Successfully we got your info.");
          } else {
            console.error("Error:", data.message);
            toast.error(data.message);
          }
        } else {
          console.error("Error:", response.statusText);
          toast.error("An error occurred. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("An error occurred. Please try again later.");
      }
    } else {
      validator.showMessages();
    }
  };

  return (
    <form onSubmit={submitHandler} className="contact-form">
      <div className="row">
        <div className="col-lg-6">
          <div className="input-field">
            <label htmlFor="text1">Name*</label>
            <div className="input-box">
              <input
                value={forms.name}
                type="text"
                name="name"
                className="form-control"
                onBlur={changeHandler}
                onChange={changeHandler}
              />
              {validator.message("name", forms.name, "required|alpha_space")}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-field">
            <label htmlFor="text2">Email*</label>
            <div className="input-box">
              <input
                value={forms.email}
                type="email"
                name="email"
                className="form-control"
                onBlur={changeHandler}
                onChange={changeHandler}
              />
              {validator.message("email", forms.email, "required|email")}
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="input-field">
            <label htmlFor="text3">Phone*</label>
            <div className="input-box">
              <input
                value={forms.phone}
                type="text"
                name="phone"
                className="form-control"
                onBlur={changeHandler}
                onChange={changeHandler}
              />
              {validator.message("phone", forms.phone, "required|phone")}
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="input-field text-field">
            <label htmlFor="text5">Message</label>
            <div className="input-box">
              <textarea
                onBlur={changeHandler}
                onChange={changeHandler}
                value={forms.message}
                name="message"
                className="form-control"
                placeholder="How can we help you?"
              />
              {validator.message("message", forms.message, "required")}
            </div>
          </div>
        </div>
      </div>
      <div className="cp-det-btn mt-20 d-grid">
        {/* <button className="cp-btn" type="submit">
          Send us a message <i className="fal fa-arrow-right"></i>
        </button> */}
        <button
          type="submit"
          name="submit"
          disabled={isLoading}
          className={isLoading ? "loading" : "cp-btn"}
        >
          {isLoading ? (
            <>
              <Spin indicator={<LoadingOutlined spin />} />
              <span style={{ marginLeft: "8px" }}>Submitting...</span>
            </>
          ) : (
            <>
              Send us a message <i className="fal fa-arrow-right"></i>
            </>
          )}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
