import React, { Fragment, useState } from "react";
import Header from "../../components/header/Header";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import Bg from "../../images/bg/page_bg01.jpg";
import icon from "../../images/icon/ser-01.svg";
import SEOHelmet from "../router/SEOHelmet";
import { useLocation } from "react-router-dom";
import BrotherImagesSection from "./BrotherImagesSection";
import Whatsapp from "../../components/common/Whatsapp";

const BrotherPrinters = () => {
  const slug = useLocation().pathname.slice(1);
  console.log("slug_slug", slug);

  return (
    <Fragment>
      <SEOHelmet slug={slug} />
      <div className="sco_agency">
        <Header />
        <section
          className="page-title pt-200 pos-rel bg_img"
          style={{ backgroundImage: `url(${Bg})` }}
        >
          <div className="container">
            <div className="page-title-wrap">
              <div className="row mt-none-30 align-items-end">
                <div className="col-xl-8 col-lg-8 mt-30">
                  <div className="page-title-box">
                    <span className="sub-title">
                      <img src={icon} alt="" /> Brother Printers
                    </span>
                    <h2 className="title">
                      Rent or Buy Brother printers for seamless
                      Printing experience
                    </h2>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 mt-30">
                  <div className="count-box">
                    <h2 className="number">
                      20 <span className="suffix">+</span>
                    </h2>
                    <span className="text">Years of experience</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <BrotherImagesSection />
        <CtaSection />
      </div>
      <Footer />
      <Whatsapp />
      <Scrollbar />
    </Fragment>
  );
};

export default BrotherPrinters;
