import React, { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/home",
  },
  {
    id: 3,
    title: "About",
    link: "/about-us",
  },
  {
    id: 5,
    title: "Solutions",
    link: "/service",
    submenu: [
      {
        id: 52,
        title: "Rental Solutions",
        link: "/printer-rental-service",
      },
      {
        id: 53,
        title: "Print Manegement Software Solutions",
        link: "/managed-print-services",
      },
      {
        id: 54,
        title: "AMC - Annual Maintenance Contract",
        link: "/annual-maintenance-contract",
      },
      {
        id: 55,
        title: "In-House Documentation",
        link: "/in-house-documentation",
      },
      {
        id: 56,
        title: "FSMA - Full Services and Maintenance Agreement",
        link: "/fsma-full-service-and-maintenance-agreement",
      },
    ],
  },
  {
    id: 7,
    title: "Partners",
    link: "#",
    submenu: [
      {
        id: 71,
        title: "Brother",
        link: "/partners/brother",
      },
      {
        id: 72,
        title: "Epson",
        link: "/partners/epson",
      },
      {
        id: 73,
        title: "Xerox",
        link: "/partners/xerox",
      },
    ],
  },
  {
    id: 6,
    title: "Resources",
    link: "/blogs",
  },
  {
    id: 88,
    title: "Contact",
    link: "/contact-us",
  },
  {
    id: 9,
    title: "Careers",
    link: "/careers",
  },
];

const MobileMenu = () => {
  const [openId, setOpenId] = useState(0);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <ul className="xb-menu-primary clearfix">
      {menus.map((item, mn) => {
        return (
          <ListItem className={item.id === openId ? "active" : null} key={mn}>
            {item.submenu ? (
              <Fragment>
                <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>
                  {item.title}
                  <i
                    className={
                      item.id === openId ? "fa fa-angle-up" : "fa fa-angle-down"
                    }
                  ></i>
                </p>
                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                  <List className="menu-item menu-item-has-children active">
                    <Fragment>
                      {item.submenu.map((submenu, i) => {
                        return (
                          <ListItem key={i}>
                            <Link
                              onClick={ClickHandler}
                              className="active"
                              to={submenu.link}
                            >
                              {submenu.title}
                            </Link>
                          </ListItem>
                        );
                      })}
                    </Fragment>
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <Link className="active" to={item.link}>
                {item.title}
              </Link>
            )}
          </ListItem>
        );
      })}
    </ul>
  );
};

export default MobileMenu;
