import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage/HomePage";
import ServicePage from "../ServicePage/ServicePage";
import AboutUsPage from "../AboutUsPage/AboutUsPage";
import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import CaseStudyPage from "../CaseStudyPage/CaseStudyPage";
import CaseStudySingle from "../CaseStudySingle/CaseStudySingle";
import CareerPage from "../CareerPage/CareerPage";
import TermsPage from "../TermsPage/TermsPage";
import PrivacyPage from "../PrivacyPage/PrivacyPage";
import BlogPage from "../BlogPage/BlogPage";
import BlogDetails from "../BlogDetails/BlogDetails";
import ContactPage from "../ContactPage/ContactPage";
import ShippingPolicy from "../Shipping-Policies/ShippingPolicies";
import RefundReturn from "../RefundReturn/RefundReturn";
import { HelmetProvider } from "react-helmet-async";
import SEOHelmet from "./SEOHelmet";
import XeroxPrinters from "../XeroxPrinters/XeroxPrinters";
import BrotherPrinters from "../BrotherPrinters/BrotherPrinters";
import EpsonPrinters from "../EpsonPrinters/EpsonPrinters";

const AllRoute = () => {
  return (
    <div className="App">
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="about-us" element={<AboutUsPage />} />
            <Route path="solutions" element={<ServicePage />} />
            <Route
              path="printer-rental-service"
              element={<ServiceSinglePage />}
            />
            <Route
              path="managed-print-services"
              element={<ServiceSinglePage />}
            />
            <Route
              path="in-house-documentation"
              element={<ServiceSinglePage />}
            />

            <Route
              path="annual-maintenance-contract"
              element={<ServiceSinglePage />}
            />

            <Route
              path="fsma-full-service-and-maintenance-agreement"
              element={<ServiceSinglePage />}
            />

            <Route path="contact-us" element={<ContactPage />} />

            <Route path="/shipping-policies" element={<ShippingPolicy />} />
            <Route
              path="/refund-and-returns-policy"
              element={<RefundReturn />}
            />
            <Route path="careers" element={<CareerPage />} />
            <Route path="terms-and-conditions" element={<TermsPage />} />
            <Route path="privacy-policy" element={<PrivacyPage />} />

            <Route path="blogs" element={<BlogPage />} />
            <Route path="/blogs/page/:page" element={<BlogPage />} />
            <Route path="category/:category" element={<BlogPage />} />
            <Route path="tag/:category" element={<BlogPage />} />

            <Route
              path="category/:category/page/:page"
              element={<BlogPage />}
            />

            <Route path="/:slug" element={<BlogDetails />} />
            <Route path="shop" element={<CaseStudyPage />} />
            <Route path="shop/page/:pageNumber" element={<CaseStudyPage />} />
            <Route path="shop/:slug" element={<CaseStudyPage />} />
            <Route
              path="shop/:slug/page/:pageNumber"
              element={<CaseStudyPage />}
            />
            <Route path="shop/:slug/:type" element={<CaseStudyPage />} />
            <Route
              path="shop/:slug/:type/page/:pageNumber"
              element={<CaseStudyPage />}
            />

            <Route path="product-category/:slug" element={<CaseStudyPage />} />
            <Route
              path="product-category/:slug/page/:pageNumber"
              element={<CaseStudyPage />}
            />
            <Route
              path="product-category/:slug/:type"
              element={<CaseStudyPage />}
            />
            <Route
              path="product-category/:slug/:type/page/:pageNumber"
              element={<CaseStudyPage />}
            />

            <Route path="product/:slug" element={<CaseStudySingle />} />
            <Route path="partners/xerox" element={<XeroxPrinters />} />
            <Route path="partners/brother" element={<BrotherPrinters />} />
            <Route path="partners/epson" element={<EpsonPrinters />} />

            {/* <Route path="contact" element={<ContactPage />} /> */}
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
};

export default AllRoute;
