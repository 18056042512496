import React from 'react';
import whatsappIcon from '../../images/icon/whatsapp-icon.png';

const Whatsapp = () => {
  return (
    <div class="floating_btn">
    <a target="_blank" href="https://wa.me/+919901994734">
      <div class="contact_icon">
        <img className='whatsapp-png' src={whatsappIcon} alt="whatsapp icon" />
      </div>
    </a>
    {/* <p class="text_icon">Talk to us?</p> */}
  </div>
  )
}

export default Whatsapp