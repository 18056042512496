import React from "react";
import sIcon1 from "../../images/icon/ab-page-icon01.svg";
import sIcon2 from "../../images/icon/ab-page-icon02.svg";
import sIcon3 from "../../images/icon/ab-page-icon03.svg";
import sIcon4 from "../../images/icon/ab-page-icon04.svg";

const FeaturesSection = (props) => {
  return (
    <section className="fanfact pt-60 pb-125">
      <div className="container">
        <div className="ap-feature_wrap">
          <div className="row mt-none-30 feature-table gap-2">
            <h1 className="text-center">About us</h1>
            <div className=" flex-nowrap gap-3 about-col">
              {/* <div className="col-lg-3 col-md-6 mt-30 table-body rounded-5"> */}
              <div
                className="ap-feature-item wow fadeInUp w-100"
                data-wow-delay="0ms"
                data-wow-duration="600ms"
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  padding: "0 20px",
                }}
              >
                <p className="xb-item--title fw-bold p-2">
                  Replica Xerography Private Limited is your premier destination
                  for all your printing needs, conveniently located in the
                  vibrant city of Bengaluru. With over 20 years of expertise in
                  the field, we have established ourselves as pioneers in
                  Printer sales, Rental and In-house documentation.
                </p>
              </div>
              {/* </div> */}
              {/* <div className="col-lg-3 col-md-6 mt-30 table-body rounded-5"> */}
              <div
                className="ap-feature-item wow fadeInUp w-100"
                data-wow-delay="100ms"
                data-wow-duration="600ms"
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  padding: "0 20px",
                }}
              >
                <p className="xb-item--title fw-bold p-2">
                  At Replica India, we believe printing is more than just
                  putting ink on paper. It’s an art, a way to express ideas, and
                  we’re here to make that magic happen for you. Whether it’s
                  documents, photos, or creative projects, we’ve got the perfect
                  printer to bring your vision to life.
                </p>
              </div>
              {/* </div> */}
              {/* <div className="col-lg-3 col-md-6 mt-30 table-body rounded-5"> */}
              <div
                className="ap-feature-item wow fadeInUp w-100"
                data-wow-delay="200ms"
                data-wow-duration="600ms"
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  padding: "0 20px",
                }}
              >
                <p className="xb-item--title fw-bold p-2">
                  When it comes to printers, we’re not just sellers; we’re your
                  printing partners. Our team is a bunch of printer enthusiasts
                  who are eager to help you find the ideal printer for your
                  unique needs. No jargon, just friendly advice!
                </p>
              </div>
              {/* </div> */}
              {/* <div className="col-lg-3 col-md-6 mt-30 table-body rounded-5"> */}
              <div
                className="ap-feature-item wow fadeInUp w-100"
                data-wow-delay="300ms"
                data-wow-duration="600ms"
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  padding: "0 20px",
                }}
              >
                <p className="xb-item--title fw-bold p-2">
                  So, dive into the world of printing wonders at Replica India.
                  Rent or buy printers online in Bangalore and unlock the joys
                  of hassle-free, high-quality printing. Get ready to embark on
                  an exciting printing journey with us!
                </p>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-lg-3 col-md-6 mt-30 delivery-content">
              <div
                className="ap-feature-item wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="600ms"
              >
                <div className="xb-item--icon">
                  <img src={sIcon1} alt="" />
                </div>
                <h4 className="xb-item--title">Top-Brand Printers</h4>
                <p className="xb-item--content">
                  Reliable Xerox, Brother, and Epson printers.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-30 delivery-content">
              <div
                className="ap-feature-item wow fadeInUp"
                data-wow-delay="100ms"
                data-wow-duration="600ms"
              >
                <div className="xb-item--icon">
                  <img src={sIcon2} alt="" />
                </div>
                <h4 className="xb-item--title">Free Maintenance</h4>
                <p className="xb-item--content">
                  AMC services for smooth, uninterrupted printing.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-30 delivery-content">
              <div
                className="ap-feature-item wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="600ms"
              >
                <div className="xb-item--icon">
                  <img src={sIcon3} alt="" />
                </div>
                <h4 className="xb-item--title">Affordable Rentals</h4>
                <p className="xb-item--content">
                  Flexible printer rental plans tailored to business needs.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mt-30 delivery-content">
              <div
                className="ap-feature-item wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="600ms"
              >
                <div className="xb-item--icon">
                  <img src={sIcon4} alt="" />
                </div>
                <h4 className="xb-item--title">Smart Print Management</h4>
                <p className="xb-item--content">
                  End-to-end print management to optimize efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
