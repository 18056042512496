
import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Bg from '../../images/bg/page_bg01.jpg'
import CtaSection from '../../components/CtaSection/CtaSection';
import icon from '../../images/icon/magic-icon.svg'
import FeaturesSection from './Features';
import WhyChoose from './WhyChoose';
import FunfactSection from './Funfact';
import Roadmap from './Roadmap';
import AwardSection from './AwardSection';
import SEOHelmet from '../router/SEOHelmet';
import Whatsapp from '../../components/common/Whatsapp';

const AboutUsPage = (props) => {

    return (
        <Fragment>
             <SEOHelmet slug={"about-us"} />

            <div className='body_wrap sco_agency'>
                <Header />
                <section className="page-title pt-200 pos-rel bg_img" style={{ backgroundImage: `url(${Bg})` }}>
                    <div className="container">
                        <div className="page-title-wrap">
                            <div className="row mt-none-30 align-items-end">
                                <div className="col-xl-8 col-lg-8 mt-30">
                                    <div className="page-title-box">
                                        <span className="sub-title"><img src={icon} alt="" /> About Replica India</span>
                                        <h2 className="title">Explore Printer Rental Solutions in Bangalore for Immaculate Printing</h2>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 mt-30">
                                    <div className="count-box">
                                        <h2 className="number">20 <span className="suffix">+</span></h2>
                                        <span className="text">Years of experience</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FeaturesSection />
                <div style={{ backgroundColor: "#f6f6f8" }}>
                    <WhyChoose />
                    <FunfactSection />
                </div>
                <Roadmap />
                <AwardSection />
                <CtaSection/>
            </div>
            <Footer />
            <Whatsapp />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutUsPage;