import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Bg from '../../images/bg/page_bg01.jpg'
import CtaSection from '../../components/CtaSection/CtaSection';
import icon from '../../images/icon/magic.svg'
import SEOHelmet from '../router/SEOHelmet';
import Whatsapp from '../../components/common/Whatsapp';

const TermsPage = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <Fragment>
             <SEOHelmet />
            <div className='body_wrap sco_agency'>
                <Header />
                <section className="page-title cp-page-title pt-200 pos-rel bg_img" style={{ backgroundImage: `url(${Bg})` }}>
                    <div className="container">
                        <div className="page-title-wrap">
                            <div className="row mt-none-30 align-items-center">
                                <div className="col-lg-8 mt-30">
                                    <div className="page-title-box">
                                        <span className="sub-title"><img src={icon} alt="" />Terms & Conditions</span>
                                        <h2 className="title">Replica Terms & Conditions</h2>
                                        <p className='privacy-text'>Please read these Terms and Conditions carefully before using our products and services. By accessing or using any of the products or services provided by Replica Xerography Private Limited, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, please do not use our products or services.</p>
                                        {/* <span className="page-update_time">Updated on : December 10th, 2024</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="terms&conditions-section cp-det-bg">
                    <div className="container">
                        <div className="terms-section_inner pt-60 pb-75">
                            <div className="row">
                                <div className="col-lg-11">
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Product Use and Intended Purpose</h2>
                                        <p>Our products are intended for personal use and display purposes only. It is strictly prohibited to use our products for any illegal, unauthorized, or commercial purposes.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Intellectual Property Rights</h2>
                                        <p>All intellectual property rights, including copyrights and trademarks, associated with our replica xerography products, content, and materials, are owned by Replica Xerography Private Limited. Any unauthorized use, reproduction, or distribution of our intellectual property is strictly prohibited.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Product Accuracy and Descriptions</h2>
                                        <p>We make every effort to accurately represent our replica xerography products through product descriptions and images. However, there might be slight variations in color or appearance due to differences in computer monitors or printing processes.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Order Acceptance</h2>
                                        <p>Placement of an order with Replica Xerography Private Limited does not constitute acceptance. We reserve the right to accept or decline any order for any reason, including but not limited to product unavailability, inaccuracies, or suspicion of fraudulent activity.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Order Confirmation</h2>
                                        <p>Upon successful order placement, you will receive an order confirmation via email. This confirmation serves as acknowledgment of your order details. In case of any discrepancies, please contact our customer support team immediately.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Payment</h2>
                                        <p>All payments for orders placed with Replica Xerography Private Limited must be made in full at the time of purchase. We accept various payment methods, and all transactions are processed through secure payment gateways.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Shipping and Delivery</h2>
                                        <p>Please refer to our Shipping Policies for information regarding delivery areas, timeframes, shipping charges, and other related details.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Cancellation and Refunds</h2>
                                        <p>We do not offer cancellation or refunds once an order is placed. For further details, please review our Cancellation and Refund Policy.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Damaged or Defective Products</h2>
                                        <p>In the event that your order arrives damaged or contains any manufacturing defects, please refer to our Shipping Policies and contact our customer support team for assistance.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Privacy and Data Protection</h2>
                                        <p>We value your privacy and are committed to protecting your personal information. Please refer to our Privacy Policy for detailed information on how we collect, use, and safeguard your data.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Limitation of Liability</h2>
                                        <p>Replica Xerography Private Limited shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use or inability to use our products or services.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title">Modifications to Terms and Conditions</h2>
                                        <p>Replica Xerography Private Limited reserves the right to update or modify these Terms and Conditions at any time without prior notice. Changes will be effective immediately upon posting on our website.</p>
                                        <p>By using our products and services, you acknowledge that you have read, understood, and agreed to abide by these Terms and Conditions. If you have any questions or concerns, please contact our customer support team for assistance.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CtaSection cClass={'bg'}/>
            </div>
            <Footer />
            <Whatsapp />
            <Scrollbar />
        </Fragment>
    )
};
export default TermsPage;
