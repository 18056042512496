import React from "react";
import { Link } from "react-router-dom";
import icon1 from "../../images/icon/sms-white.svg";
import icon2 from "../../images/icon/call-white.svg";
import icon3 from "../../images/icon/location.svg";
import Services from "../../api/service";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const SubmitHandler = (e) => {
  e.preventDefault();
};

const Footer = (props) => {
  return (
    <footer
      className="footer footer-style-two pt-200 bg_img pos-rel"
      style={{ backgroundColor: "#0c111d" }}
    >
      <div className="container">
        <div className="xb-footer pt-120">
          <div className="footer-info ul_li_between">
            <div className="info-item ul_li">
              <div className="xb-item--icon">
                <span>
                  <img src={icon1} alt="" />
                </span>
              </div>
              <div className="xb-item--holder">
                <p className="xb-item--content">Write to us</p>
                <h4 className="xb-item--title">sales@replicaindia.com</h4>
              </div>
            </div>
            <div className="info-item ul_li">
              <div className="xb-item--icon clr-blue">
                <span>
                  <img src={icon2} alt="" />
                </span>
              </div>
              <div className="xb-item--holder">
                <p className="xb-item--content">Call Us </p>
                <h4 className="xb-item--title">
                  +(91) 9901994734 <br /> +(91) 80 4127 3061
                </h4>
              </div>
            </div>
            <div className="info-item ul_li">
              <div className="xb-item--icon clr-sky">
                <span>
                  <img src={icon3} alt="" />
                </span>
              </div>
              <div className="xb-item--holder">
                <p className="xb-item--content">Our Office</p>
                <h4 className="xb-item--title">
                  B-375, Outer Ring Rd, 1St Road,
                  <br /> Peenya 1st Stage, Netaji Nagar, Peenya, <br />
                  Bengaluru, Karnataka 560058
                </h4>
              </div>
            </div>
          </div>
          <div className="footer-inner mt-70 mb-30 ul_li_between align-items-start">
            <div className="sa-newslatter footer-widget">
              <span className="xb-item--sub-title">About Company</span>
              <p className="xb-item--content clr-white">
                Replica Xerography Private Limited is your premier destination
                for all your printing needs, conveniently located in the vibrant
                city of Bengaluru. With over 20 years of expertise in the field,
                we have established ourselves as pioneers in Printer sales.
              </p>
              {/* <form className="xb-item--input_field pos-rel" onSubmit={SubmitHandler}>
                                <input type="email" name="gmail" id="text6" placeholder="enter your email" />
                                <div className="img"><img src={icon4} alt="" /></div>
                                <button type="submit" className="xb-item--btn"><i className="fas fa-paper-plane"></i></button>
                            </form>
                            <span className="xb-item--text">By continuing, you agree to replica <Link onClick={ClickHandler} to="/terms-and-conditions">Terms</Link> of
                                Use and <Link onClick={ClickHandler} to="/privacy-policy">Privacy Policy</Link></span> */}
            </div>
            <div className="footer-widget">
              <span className="xb-item--sub-title">Policies</span>
              <ul className="xb-item--holder list-unstyled">
                {/* <li className="xb-item--list"><Link onClick={ClickHandler} to="/about">About us</Link></li> */}
                {/* <li className="xb-item--list"><Link onClick={ClickHandler} to="/contact-us">Contact</Link></li> */}
                <li className="xb-item--list">
                  <Link onClick={ClickHandler} to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
                <li className="xb-item--list">
                  <Link onClick={ClickHandler} to="/shipping-policies">
                    Shipping Policies
                  </Link>
                </li>
                <li className="xb-item--list">
                  <Link onClick={ClickHandler} to="/terms-and-conditions">
                    Term & Conditions
                  </Link>
                </li>
                <li className="xb-item--list">
                  <Link onClick={ClickHandler} to="/refund-and-returns-policy">
                    Refund & Return Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-widget">
              <span className="xb-item--sub-title">Our Solutions</span>
              <ul className="xb-item--holder list-unstyled">
                {Services.slice(0, 4).map((service, srv) => (
                  <li key={srv} className="xb-item--list">
                    {service.title ? (
                      <Link onClick={ClickHandler} to={`/${service.slug}`}>
                        <span className="icon_list_text">{service.title}</span>
                      </Link>
                    ) : (
                      ""
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-widget">
              <span className="xb-item--sub-title">Our Shop</span>
              <ul className="xb-item--holder list-unstyled">
                <li className="xb-item--list">
                  <Link
                    onClick={ClickHandler}
                    to="/shop"
                  >
                    Shop
                  </Link>
                </li>
                <li className="xb-item--list">
                  <Link
                    onClick={ClickHandler}
                    to="/product-category/all-brands"
                  >
                    Printers
                  </Link>
                </li>
                <li className="xb-item--list">
                  <Link onClick={ClickHandler} to="/product-category/cartridge">
                    Ink / Cartridge
                  </Link>
                </li>
                <li className="xb-item--list">
                  <Link onClick={ClickHandler} to="/product-category/drum-unit">
                    Spare Parts
                  </Link>
                </li>
                {/* <li className="xb-item--list"><Link onClick={ClickHandler} to="/">Insurance</Link></li>
                                <li className="xb-item--list"><Link onClick={ClickHandler} to="/">Crypto</Link></li>
                                <li className="xb-item--list"><Link onClick={ClickHandler} to="/">Automotive</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="footer-copyright mt-50 ul_li_between">
            <p className="copyright mt-20">
              Copyright © 2025{" "}
              <Link onClick={ClickHandler} to="/">
                Replica Xerography
              </Link>
              . All rights reserved. Designed & Developed By{" "}
              <Link onClick={ClickHandler} to="https://hirolainfotech.com/">
                Hirola InfoTech Solutions
              </Link>
            </p>
            <ul className="footer-link ul_li mt-20">
              <li>
                <span>Follow us :</span>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/replicaindia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <Link
                  onClick={ClickHandler}
                  to="https://in.pinterest.com/replicaxeropraphy/"
                  target="_blank"
                >
                  <i className="fab fa-pinterest"></i>
                </Link>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/replica_xerography_pvt_ltd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/replica-xerography-private-limited/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                {/* <Link onClick={ClickHandler} to="/"><i className="fab fa-linkedin-in"></i></Link> */}
              </li>
              {/* <li>
                                <Link onClick={ClickHandler} to="/"><i className="fab fa-youtube"></i></Link>
                            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
