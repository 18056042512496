import { useRef, useEffect } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Fade } from "react-awesome-reveal";
import 'swiper/css';
import 'swiper/css/navigation';
import tImg1 from '../../images/testimonial/google.png'
import quote from '../../images/icon/quta.png'
import hicon from '../../images/icon/like-icon.svg'



const testimonial = [
    {
        id: '01',
        tImg: tImg1,
        Des: "Gave my Epson printer for a minor repair and service. I was greeted by amazing service and a fast and seamless experience. Would highly recommend.",
        Name: 'Ani Raju',
        sub: 'Brand Strat at near',
        country: 'Seattle, Ukraine',
    },
    {
        id: '02',
        tImg: tImg1,
        Des: "Wonderful experience & well trained staff. Mr.Kiran was very cooperative. Overall good experience.",
        Name: 'Prashanth R.Katte',
        sub: 'Branding at spinlet',
    },
    {
        id: '03',
        tImg: tImg1,
        Des: "Customer support is very good, and staff is very cooperative, qualility is nice, and rate reasonable, Vijay, Bangalore",
        Name: 'Vijay Kumar',
        sub: 'Marketing Dir. at BUSD',
    },
    {
        id: '04',
        tImg: tImg1,
        Des: "I was able complete the entire steps of choosing the printer to raising the call for printer installation on whatsapp.",
        Name: 'Naren N',
        sub: 'Project Manager at Kuda',
    },
    {
        id: '05',
        tImg: tImg1,
        Des: "Replica Xerography’s printer management software is simplicity redefined.",
        Name: 'Bhoomika Kesarkar',
        sub: 'Project Manager at Kuda',
    },
    {
        id: '06',
        tImg: tImg1,
        Des: "I recently purchased digital prints from Replica and the quality of prints is exceptional and their was very responsive.great choice for any printing needs",
        Name: 'Abhishek',
        sub: 'Branding at spinlet',
    },
]

const Testimonial = (props) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current && prevRef.current && nextRef.current) {
            swiperRef.current.params.navigation.prevEl = prevRef.current;
            swiperRef.current.params.navigation.nextEl = nextRef.current;
            swiperRef.current.navigation.init();
            swiperRef.current.navigation.update();
        }
    }, []);

    return (

        <section className={`testimonial o-hidden ${props.tClass} ${props.pbclass}`}>
            <div className="container">
                <div className="sa-tes_top pos-rel mb-60">
                    <div className="sec-title--two">
                        <Fade direction='down' triggerOnce={'false'} duration={1000} delay={9}>
                            <div>
                                <div className="sub-title wow fadeInDown" data-wow-duration="600ms"><img
                                    src={hicon} alt="" />99% Customer Satisfaction</div>
                            </div>
                        </Fade>
                        <Fade direction='up' triggerOnce={'false'} duration={1200} delay={9}>
                            <div>
                                <h2 className="title wow fadeInDown" data-wow-delay="150ms" data-wow-duration="600ms">Trusted by Our Customers</h2>
                            </div>
                        </Fade>
                    </div>
                    <div className="sa-tes_button">
                        <div className="sa-swiper-btn swiper-button-prev" ref={prevRef}><i className="fal fa-angle-left"></i></div>
                        <div className="sa-swiper-btn active swiper-button-next" ref={nextRef}><i className="fal fa-angle-right"></i></div>
                    </div>
                </div>
            </div>
            <div className="sa-testimonial-slider-inner">
                <div className="sa-testimonial-slider">
                    <div className="swiper-wrapper">
                        <Swiper
                            // install Swiper modules
                            modules={[Navigation]}
                            spaceBetween={50}
                            slidesPerView={5}
                            loop={true}
                            speed={1800}
                            parallax={true}
                            ref={swiperRef}
                            breakpoints={{
                                1600: {
                                    slidesPerView: 4,
                                },
                                768: {
                                    slidesPerView: 4,
                                    centeredSlides: false,
                                },
                                576: {
                                    slidesPerView: 2,
                                },
                                0: {
                                    slidesPerView: 1,
                                },
                            }}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                        >
                            {testimonial.map((testimonial, tsm) => (
                                <SwiperSlide key={tsm}>
                                    <div className="sa-testimonial-item">
                                        <div className="xb-item--inner">
                                            <div className="xb-item--quta">
                                                <img src={quote} alt="" />
                                            </div>
                                            <p className="xb-item--content">"{testimonial.Des}"</p>
                                            <div className="xb-item--holder ul_li">
                                                <div className="xb-item--avatar">
                                                    <img src={testimonial.tImg} alt="" style={{width:'60px',height:'auto'}}/>
                                                </div>
                                                <div className="xb-item--author">
                                                    <h3 className="xb-item--name">{testimonial.Name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;