import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "bootstrap-icons/font/bootstrap-icons.css";  
import shape1 from '../../images/shape/line-shape03.png';
import shape2 from '../../images/shape/line-shape04.png';
import shape3 from '../../images/shape/pattern02.png';
import shape4 from '../../images/shape/monitor-shape.png';
import shape5 from '../../images/shape/blur-shape.png';

const RoadmapSlider = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});
    const charLimit = 150;

    const toggleReadMore = (id) => {
        setExpandedDescriptions((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const roadmapData = [
        {
            id: 1,
            quarter: "Q-1",
            yearIcon: "bi-rocket-takeoff", 
            title: "Unleash Your Creativity with Stunning Prints",
            content:
                "At Replica Xerography, we specialize in transforming your ideas into reality through our extensive range of printing solutions. From exquisite business cards that leave a lasting impression to meticulously crafted courseware books....",
        },
        {
            id: 2,
            quarter: "Q-2",
            yearIcon: "bi-gem",
            title: "Efficiency Meets Flexibility with Printer Rentals",
            content:
                "We understand that every project has unique requirements, and that's why we offer hassle-free printer rental solutions to meet your temporary printing needs. Whether you're organizing a corporate event...",
        },
        {
            id: 3,
            quarter: "Q-3",
            yearIcon: "bi-rocket-takeoff",
            title: "Exceeding Expectations through Exceptional Service",
            content:
                "At Replica Xerography, we are passionate about delivering an exceptional customer experience. We go above and beyond to surpass your expectations by providing superior printing solutions...",
        },
        {
            id: 4,
            quarter: "Q-4",
            yearIcon: "bi-headset",
            title: "Unleash the Power of Cutting-Edge Printing Technology",
            content:
                "As authorized partners of industry-leading brands like Xerox, Epson, and Brother, we bring you the latest advancements in printing technology. Our range of printers for sale combines exceptional performance...",
        },
        {
            id: 5,
            quarter: "Q-5",
            yearIcon: "bi-rocket-takeoff",
            title: "Harnessing the Best in Printing Technology",
            content:
                "As trusted partners of leading brands like Xerox, Epson, and Brother, we provide access to the most advanced printing technology. Whether you need printers for purchase or rental, our reliable, high-performance models help you achieve efficiency and excellence in every print. Let us equip you with the right tools for success.",
        },
    ];

    return (
        <section className="roadmap roadmap-pb pt-130 pb-200 pos-rel" style={{ backgroundColor: "#0F55DC" }}>
            <div className="container">
                <div className="sa-tes_top roadmap-top pos-rel mb-85">
                    <div className="sec-title--two">
                        <div className="sub-title sub-title--strock wow fadeInDown" data-wow-duration="600ms">
                            <img src="assets/img/icon/process-icon.svg" alt="" />Our Journey
                        </div>
                        <h2 className="title clr-white wow fadeInDown" data-wow-delay="150ms" data-wow-duration="600ms">
                            Reasons to Count on US
                        </h2>
                    </div>
                    <div className="sa-tes_button roadmap-button">
                        <div className="sa-swiper-btn swiper-button-prev" ref={prevRef}><i className="fal fa-angle-left"></i></div>
                        <div className="sa-swiper-btn active swiper-button-next" ref={nextRef}><i className="fal fa-angle-right"></i></div>
                    </div>
                </div>
            </div>
            <div className="roadmap-slider-container">
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    loop={true}
                    spaceBetween={50}
                    speed={400}
                    slidesPerView={3}
                    centeredSlides={true}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        el: ".swiper-pagination",
                        clickable: true,
                    }}
                    navigation={{
                        nextEl: nextRef.current,
                        prevEl: prevRef.current,
                    }}
                    breakpoints={{
                        1600: { slidesPerView: 3 },
                        1024: { slidesPerView: 3, spaceBetween: 30 },
                        768: { slidesPerView: 2 },
                        576: { slidesPerView: 1 },
                        0: { slidesPerView: 1 },
                    }}
                    className="raodmap-slider"
                >
                    {roadmapData.map((item) => {
                        const isExpanded = expandedDescriptions[item.id];
                        const isLongText = item.content.length > charLimit;
                        const shortText = item.content.substring(0, charLimit) + "...";

                        return (
                            <SwiperSlide key={item.id}>
                                <div className="roadmap-item">
                                    <div className="xb-item--top">
                                        <img src={shape1} alt="" />
                                        <span className="xb-item--ques"><i className={`bi ${item.yearIcon}`}></i></span>
                                        <img src={shape2} alt="" />
                                    </div>
                                    <div className="xb-item--holder">
                                        <h3 className="xb-item--title">{item.title}</h3>
                                        <p className="xb-item--content p-2">
                                            {isExpanded ? item.content : shortText}
                                            {isLongText && (
                                                <button onClick={() => toggleReadMore(item.id)} className="text-blue-500 underline mt-2 ms-2 bg-transparent border-0">
                                                    {isExpanded ? "Read Less" : "Read More"}
                                                </button>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <div className="swiper-pagination"></div>
            </div>
            <div className="roadmap-shape">
                <div className="shape shape--one"><img src={shape3} alt=""/></div>
                <div className="shape shape--two"><img src={shape4} alt=""/></div>
                <div className="shape shape--three"><img src={shape5} alt=""/></div>
            </div>
        </section>
    );
};

export default RoadmapSlider;
