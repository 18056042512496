
import React, { useState } from 'react';
import hicon from '../../images/icon/magic.svg'
import { Fade } from "react-awesome-reveal";
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="faq pb-80 pt-80 faq-bottom">
            <div className="container">
                <div className="sec-title--two text-center mb-60">
                    <Fade direction='down' triggerOnce={'false'} duration={1000} delay={9}>
                        <div>
                            <div className="sub-title wow fadeInDown" data-wow-duration="600ms"><img
                                src={hicon} alt="" />FAQ’s</div>
                        </div>
                    </Fade>
                    <Fade direction='up' triggerOnce={'false'} duration={1200} delay={9}>
                        <div>
                            <h2 className="title wow fadeInDown" data-wow-delay="150ms" data-wow-duration="600ms">Have a question? Check here for answers!</h2>
                        </div>
                    </Fade>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="xb-faq wow fadeInUp" data-wow-delay="200ms" data-wow-duration="600ms">
                            <Accordion open={open} toggle={toggle} className='accordion_box clearfix list-unstyled'>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="1" className='acc-btn'><span className="number">01. </span> <p className='question-text'> What types of printers and copiers do you offer?</p>
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="1" className='acc_body'>
                                        <div className="content">
                                            <p>We provide a wide range of printers and copiers, including multifunction devices, 
                                                laser printers, and high-speed production printers for businesses of all sizes.</p>
                                            {/* <ul className="list-unstyled">
                                                <li><i className="far fa-check"></i>The age and authority of your site.
                                                </li>
                                                <li><i className="far fa-check"></i>Your on-page and off-page
                                                    optimization.</li>
                                                <li><i className="far fa-check"></i>Any penalties pulling your ranking
                                                    down.</li>
                                            </ul> */}
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="2" className='acc-btn'><span className="number">02.</span> <p className='question-text'> How can Print Management Software Solutions (MPS) reduce my printing costs? </p>
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2" className='acc_body'>
                                        <div className="content">
                                            <p>MPS optimizes your printing environment, reduces paper and toner waste, and ensures cost-effective device management.</p>
                                            {/* <ul className="list-unstyled">
                                                <li><i className="far fa-check"></i>The age and authority of your site.
                                                </li>
                                                <li><i className="far fa-check"></i>Your on-page and off-page
                                                    optimization.</li>
                                                <li><i className="far fa-check"></i>Any penalties pulling your ranking
                                                    down.</li>
                                            </ul> */}
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="3" className='acc-btn'><span className="number">03.</span> <p className='question-text'> How often will my equipment be serviced under AMC?</p>
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3" className='acc_body'>
                                        <div className="content">
                                            <p>We provide regular maintenance visits based on the contract and offer emergency servicing when needed.</p>
                                            {/* <ul className="list-unstyled">
                                                <li><i className="far fa-check"></i>The age and authority of your site.
                                                </li>
                                                <li><i className="far fa-check"></i>Your on-page and off-page
                                                    optimization.</li>
                                                <li><i className="far fa-check"></i>Any penalties pulling your ranking
                                                    down.</li>
                                            </ul> */}
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="4" className='acc-btn'><span className="number">04.</span> <p className='question-text'> What industries benefit most from in-house documentation services? </p>
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="4" className='acc_body'>
                                        <div className="content">
                                            <p>Industries like healthcare, legal, education, and corporate businesses benefit 
                                                from in-house documentation by streamlining printing, scanning, and record-keeping.</p>
                                            {/* <ul className="list-unstyled">
                                                <li><i className="far fa-check"></i>The age and authority of your site.
                                                </li>
                                                <li><i className="far fa-check"></i>Your on-page and off-page
                                                    optimization.</li>
                                                <li><i className="far fa-check"></i>Any penalties pulling your ranking
                                                    down.</li>
                                            </ul> */}
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem className='block'>
                                    <AccordionHeader targetId="5" className='acc-btn'><span className="number">05.</span> <p className='question-text'> What does FSMA cover?</p>
                                        <span className="arrow"></span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="5" className='acc_body'>
                                        <div className="content">
                                            <p>FSMA covers printer maintenance, repair, toner refilling, and regular servicing to ensure optimal performance.</p>
                                            {/* <ul className="list-unstyled">
                                                <li><i className="far fa-check"></i>The age and authority of your site.
                                                </li>
                                                <li><i className="far fa-check"></i>Your on-page and off-page
                                                    optimization.</li>
                                                <li><i className="far fa-check"></i>Any penalties pulling your ranking
                                                    down.</li>
                                            </ul> */}
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};
export default FaqSection;
