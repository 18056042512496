import React from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import sIcon1 from "../../images/icon/ser-01.svg";
import brand1 from "../../images/award/ap-brand01.jpg";
import brand2 from "../../images/award/ap-brand02.jpg";
import brand3 from "../../images/award/ap-brand03.jpg";
import brand4 from "../../images/award/ap-brand04.jpg";
import brand5 from "../../images/award/ap-brand05.jpg";
import brand6 from "../../images/award/ap-brand06.jpg";
import brand7 from "../../images/award/ap-brand07.jpg";
import brand8 from "../../images/award/ap-brand08.jpg";
import brand9 from "../../images/award/ap-brand09.jpg";
import brand10 from "../../images/award/ap-brand10.jpg";
import sIcon11 from "../../images/icon/ab-page-icon01.svg";
import sIcon2 from "../../images/icon/ab-page-icon02.svg";
import sIcon3 from "../../images/icon/ab-page-icon03.svg";
import sIcon4 from "../../images/icon/ab-page-icon04.svg";

const AwardSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <section className="award" style={{ backgroundColor: "#f6f6f8" }}>
      <div className="container">
        <div className="row mt-none-30 align-items-center">
          <div className="col-xl-6 col-lg-5 mt-30">
            <div className="service-info ab-award-content pt-100">
              <div className="sec-title--two">
                <Fade
                  direction="down"
                  triggerOnce={"false"}
                  duration={1000}
                  delay={9}
                >
                  <div>
                    <span
                      className="sub-title wow fadeInDown"
                      data-wow-duration="600ms"
                    >
                      <img src={sIcon1} alt="" />
                      Our awards
                    </span>
                  </div>
                </Fade>
                <Fade
                  direction="up"
                  triggerOnce={"false"}
                  duration={1200}
                  delay={9}
                >
                  <div>
                    <h2
                      className="title wow skewIn"
                      data-wow-delay="100ms"
                      data-wow-duration="600ms"
                    >
                      Learn about our solutions
                    </h2>
                  </div>
                </Fade>
                <Fade
                  direction="up"
                  triggerOnce={"false"}
                  duration={1200}
                  delay={9}
                >
                  <div>
                    <p
                      className="content wow fadeInUp"
                      data-wow-delay="300ms"
                      data-wow-duration="600ms"
                    >
                      We provide premier destination for all your printing needs
                      with over 20 years of expertise in the field, we have
                      established ourselves as pioneers in Printers
                      Rental and In-house documentation.
                    </p>
                  </div>
                </Fade>
              </div>
              <Fade
                direction="up"
                triggerOnce={"false"}
                duration={1200}
                delay={9}
              >
                <div>
                  <div
                    className="xb-btn mt-50 wow fadeInUp view-more-btn"
                    data-wow-delay="450ms"
                    data-wow-duration="600ms"
                  >
                    <Link
                      to="/contact-us"
                      onClick={ClickHandler}
                      className="thm-btn thm-btn--aso thm-btn--aso_yellow view-btn-size"
                    >
                      Book a free consultation
                    </Link>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 mt-30">
            <div className="ap-award-wrap ul_li">
              <div className="ap-award-inner marquee-first">
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand1} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand2} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand3} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand1} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand2} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand3} alt="" />
                  </div>
                </div>
              </div>
              <div className="ap-award-inner marquee-2">
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand4} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand5} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand6} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand7} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand4} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand5} alt="" />
                  </div>
                </div>
              </div>
              <div className="ap-award-inner marquee-first marquee-last">
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand8} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand9} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand10} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand8} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand9} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand10} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-20 pb-60 ">
          <div className="col-lg-3 col-md-6 mt-30 delivery-content">
            <div
              className="ap-feature-item wow fadeInUp"
              data-wow-delay="0ms"
              data-wow-duration="600ms"
            >
              <div className="xb-item--icon">
                <img src={sIcon11} alt="" />
              </div>
              <h4 className="xb-item--title">Printer Rental Solutions</h4>
              <p className="xb-item--content">
                Your trusted partner in delivering unparalleled printer rental
                services.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-30 delivery-content">
            <div
              className="ap-feature-item wow fadeInUp"
              data-wow-delay="100ms"
              data-wow-duration="600ms"
            >
              <div className="xb-item--icon">
                <img src={sIcon2} alt="" />
              </div>
              <h4 className="xb-item--title">Print Management Software Solutions</h4>
              <p className="xb-item--content">
                Your key to optimizing and controlling the entire print
                ecosystem.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-30 delivery-content">
            <div
              className="ap-feature-item wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="600ms"
            >
              <div className="xb-item--icon">
                <img src={sIcon3} alt="" />
              </div>
              <h4 className="xb-item--title">In-House Documentation</h4>
              <p className="xb-item--content">
                We have become the preferred choice for large corporates,
                industries, schools.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-30 delivery-content">
            <div
              className="ap-feature-item wow fadeInUp"
              data-wow-delay="300ms"
              data-wow-duration="600ms"
            >
              <div className="xb-item--icon">
                <img src={sIcon4} alt="" />
              </div>
              <h4 className="xb-item--title">AMC</h4>
              <p className="xb-item--content">
                Ensure seamless printing with our AMC – maintenance, support,
                and timely service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AwardSection;
