import React, { Fragment, useEffect, useRef } from "react";
import Header from "../../components/header/Header";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Scrollbar from "../../components/scrollbar/scrollbar";
import CtaSection from "../../components/CtaSection/CtaSection";
import Footer from "../../components/footer/Footer";
import cIcon from "../../images/icon/check-icon.svg";
import { Navigation } from "swiper";
import icon1 from "../../images/icon/prev-icon.png";
import icon2 from "../../images/icon/next-icon.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";
import { Fade } from "react-awesome-reveal";
import SEOHelmet from "../router/SEOHelmet";
import Whatsapp from "../../components/common/Whatsapp";

const CaseStudySingle = (props) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const products = require("../../json/products.json");

  const { slug } = useParams();

  const productDetails = products.find((item) => item.slug === slug);

  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, []);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const requestProductQuote = () => {
    navigate("/contact-us", { state: { productDetails } });
  };

  const renderFormattedContent = (content) => {
    if (!content) return null;

    const parts = content.split(/(\[\*\*\][^\[]*?\[\*\*\]|\[.*?\]\(.*?\))/g);

    return parts.map((part, index) => {
      if (part.startsWith("[**]") && part.endsWith("[**]")) {
        const boldText = part.substring(4, part.length - 4);
        return (
          <strong key={index}>
            {" "}
            {boldText}
            {""}
          </strong>
        );
      } else if (part.match(/\[(.*?)\]\((.*?)\)/)) {
        const match = part.match(/\[(.*?)\]\((.*?)\)/);
        const linkText = match[1];
        const linkUrl = match[2];
        return (
          <a
            href={linkUrl}
            key={index}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {linkText}{" "}
          </a>
        );
      } else {
        return <> {part}</>;
      }
    });
  };

  const renderContent = (item) => {
    if (
      item.type === "h2" &&
      item.content === "Description" &&
      productDetails.main_description &&
      productDetails.main_description[0] === item
    ) {
      return null;
    }

    switch (item.type) {
      case "h1":
        return (
          <h1 className="content-heading-xl">
            {renderFormattedContent(item.content)}
          </h1>
        );
      case "h2":
        return (
          <h2 className="content-heading-lg">
            {renderFormattedContent(item.content)}
          </h2>
        );
      case "h3":
        return (
          <h3 className="content-heading-md">
            {renderFormattedContent(item.content)}
          </h3>
        );
      case "h4":
        return (
          <h4 className="content-heading-sm">
            {renderFormattedContent(item.content)}
          </h4>
        );
      case "h5":
        return (
          <h5 className="content-heading-xs">
            {renderFormattedContent(item.content)}
          </h5>
        );
      case "h6":
        return (
          <h6 className="content-heading-xxs">
            {renderFormattedContent(item.content)}
          </h6>
        );
      case "p":
        return (
          <p className="content-paragraph">
            {renderFormattedContent(item.content)}
          </p>
        );
      case "text":
        return (
          <p className="content-text">{renderFormattedContent(item.content)}</p>
        );

      case "ul":
        return (
          <ul className="sd-ser-list list-unstyled">
            {item.content.map((li, index) => (
              <div
                key={index}
                className="list-item-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <img className="list-icon" src={cIcon} alt="" />
                <li className="list-item-text">{renderFormattedContent(li)}</li>
              </div>
            ))}
          </ul>
        );
      case "ol":
        return (
          <ol className="sd-ser-list-numbered">
            {item.content.map((li, index) => (
              <li key={index} className="list-item">
                {renderFormattedContent(li)}
              </li>
            ))}
          </ol>
        );

      case "blockquote":
        return (
          <blockquote className="sd-blockquote">
            {renderFormattedContent(item.content)}
          </blockquote>
        );
      case "code":
        return (
          <pre className="sd-code">
            <code>{item.content}</code>
          </pre>
        );

      case "table":
        return (
          <div className="table-responsive">
            <table className="table table-bordered">
              <tbody>
                {item.content.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={
                      rowIndex === 0 ? "table-header-row" : "table-data-row"
                    }
                  >
                    {row.map((cell, cellIndex) =>
                      rowIndex === 0 || cellIndex === 0 ? (
                        <th key={cellIndex} className="table-header-cell">
                          {renderFormattedContent(cell)}
                        </th>
                      ) : (
                        <td key={cellIndex} className="table-data-cell">
                          {renderFormattedContent(cell)}
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );

      default:
        return (
          <div className={`content-${item.type || "unknown"}`}>
            {renderFormattedContent(item.content)}
          </div>
        );
    }
  };

  const renderRelatedProducts = () => {
    const related = products
      .filter((product) => {
        if (product.slug === slug) return false;

        if (!productDetails.categories || !product.categories) return false;

        return product.categories.some((cat1) =>
          productDetails.categories.some((cat2) => cat1.name === cat2.name)
        );
      })
      .slice(0, 3);

    if (related.length === 0) return null;
    return (
      <div className="row mt-none-30">
        {related.map((product, index) => (
          <div className="col-lg-4 col-md-6 mt-30" key={index}>
            <div className="casestudy-item">
              <div className="casestudy-img">
                <Link onClick={ClickHandler} to={`/product/${product.slug}`}>
                  {product.images && product.images.length > 0 ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/${product.images[0].src}`}
                      alt={product.images[0].alt}
                    />
                  ) : (
                    <div className="no-image">No Image</div>
                  )}
                </Link>
                <div className="content_wrap">
                  <h3 className="item_title">{product.title}</h3>
                </div>
              </div>
              <Link
                onClick={ClickHandler}
                to={`/product/${product.slug}`}
                className="xb-overlay"
              ></Link>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (!productDetails) {
    return (
      <Fragment>
        <Header />
        <div className="container pt-200 pb-200">
          <h2>Product not found</h2>
          <p>The product you are looking for does not exist.</p>
        </div>
        <Footer />
        <Scrollbar />
      </Fragment>
    );
  }
  return (
    <Fragment>
      <SEOHelmet slug={slug} />

      <div className="body_wrap sco_agency">
        <Header />

        <div className="csd-img pt-200 pb-65">
          <div className="container">
            {productDetails.images && productDetails.images.length > 0 ? (
              <div className="blog-slider  swiper-container pos-rel width75">
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={50}
                  slidesPerView={1}
                  loop={true}
                  speed={1800}
                  parallax={true}
                  ref={swiperRef}
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {productDetails.images.map((product, Bitem) => (
                    <SwiperSlide key={Bitem}>
                      <div className="blog-slide-item">
                        <div className="xb-item--img">
                          <img
                            style={{
                              maxWidth: "40%",
                              height: "auto",
                              aspectRatio: "video",
                              display: "block",
                              margin: "0 auto",
                              height: "40vh",
                            }}
                            src={`${process.env.PUBLIC_URL}/${product.src}`}
                            alt={product.alt}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="swiper-pagination"></div>
                <div className="blog-item_button">
                  <div
                    className="blog-swiper-btn swiper-button-prev"
                    ref={prevRef}
                  >
                    <img src={icon1} alt="Previous" />
                  </div>
                  <div
                    className="blog-swiper-btn swiper-button-next"
                    ref={nextRef}
                  >
                    <img src={icon2} alt="Next" />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="sd-ser-content_wrap pb-60">
          <div className="container">
            <div className="sd-ser-content">
              <h2 className="sd-title">{productDetails.title}</h2>
              {productDetails.short_description &&
                productDetails.short_description.length > 0 && (
                  <div className="casestudy-short-description pb-10">
                    <div className="container">
                      <div className="description-content">
                        {productDetails.short_description.map((item, index) => (
                          <div key={index}>{renderContent(item)}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div>
          <Fade
            direction="up"
            triggerOnce={"false"}
            duration={1200}
            style={{
              cursor: "pointer",
            }}
            delay={9}
          >
            <div>
              <div
                className="xb-btn mt-20 ml-130 wow fadeInUp"
                data-wow-duration="600ms"
              >
                <div className="view-more-btn">
                  <div
                    onClick={requestProductQuote}
                    className="thm-btn thm-btn--aso thm-btn--aso_yellow view-btn-size"
                  >
                    Get Quote
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        {productDetails.main_description.length ? (
          <div className="mt-20 product-main-description-section pb-120">
            <div className="container">
              <div className="main-description-container">
                <div className="section-heading">
                  <h2 className="section-title">Product Description</h2>
                  <div className="section-title-underline"></div>
                </div>

                {productDetails.main_description &&
                  productDetails.main_description.length > 0 && (
                    <div className="product-main-description">
                      <div className="description-content">
                        {productDetails.main_description.map((item, index) => {
                          if (
                            index === 0 &&
                            item.type === "h2" &&
                            item.content === "Description"
                          ) {
                            return null;
                          }
                          return (
                            <div
                              key={index}
                              className="description-content-item"
                            >
                              {renderContent(item)}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
              </div>

              <div className="similar-casestudy pt-80">
                <h2 className="similar-casestudy-title">
                  Our similar Products
                </h2>
                {renderRelatedProducts()}
              </div>
            </div>
          </div>
        ) : null}

        <CtaSection />
      </div>
      <Footer />
      <Whatsapp />
      <Scrollbar />
    </Fragment>
  );
};
export default CaseStudySingle;
