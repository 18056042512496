import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import Hero from '../../components/hero/hero';
import PartnerSection from '../../components/PartnerSection';
import About from '../../components/about/about';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import WorkProcess from '../../components/WorkProcess/WorkProcess';
import IndustrieSection from '../../components/IndustrieSection/IndustrieSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import CtaSection from '../../components/CtaSection/CtaSection';
import FaqSection from '../../components/FaqSection/FaqSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import SEOHelmet from '../router/SEOHelmet';
import Whatsapp from '../../components/common/Whatsapp';


const HomePage = () => {

    return (
        <Fragment>
             <SEOHelmet slug={"home"} />
            <div className='body_wrap sco_agency'>
                <Header />
                <main className="page_content">
                    <Hero />
                    <PartnerSection />
                    <About />
                    <ServiceSection />
                    <ProjectSection />
                    <WorkProcess />
                    <IndustrieSection />
                    <Testimonial />
                    <FaqSection />
                    <CtaSection /> 
                </main>
                <Footer />
                <Whatsapp />
                <Scrollbar />
            </div>
        </Fragment>
    )
};
export default HomePage;