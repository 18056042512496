import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Bg from '../../images/bg/page_bg01.jpg'
import CtaSection from '../../components/CtaSection/CtaSection';
import icon from '../../images/icon/magic.svg'
import SEOHelmet from '../router/SEOHelmet';
import Whatsapp from '../../components/common/Whatsapp';

const PrivacyPage = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <Fragment>
             <SEOHelmet />
            <div className='body_wrap sco_agency'>
                <Header />
                <section className="page-title cp-page-title pt-200 pos-rel bg_img" style={{ backgroundImage: `url(${Bg})` }}>
                    <div className="container">
                        <div className="page-title-wrap">
                            <div className="row mt-none-30 align-items-center">
                                <div className="col-lg-8 mt-30">
                                    <div className="page-title-box">
                                        <span className="sub-title"><img src={icon} alt="" />Shipping Policy</span>
                                        <h2 className="title">Replica Shipping Policy</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="terms&conditions-section cp-det-bg">
                    <div className="container">
                        <div className="terms-section_inner pt-40 pb-75">
                            <div className="row">
                                <div className="col-lg-11">
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title mb-10">Delivery Area</h2>
                                        <p>We provide shipping services exclusively within Bangalore Urban and Rural limits. Please ensure your delivery address falls within our designated Bangalore Pincode areas. For locations outside Bengaluru, Courier charges will be extra.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title mb-10">Delivery Timeframe</h2>
                                        <p>Subsequent to confirmation of the order, we shall process the said order within 4 business days from the date of confirmation, however, subject to availability of the product/s.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title mb-10">For Deliveries Outside Bangalore</h2>
                                        <p>Courier Charges apply. Please Contact No. <span className='fw-bold'> 9901994734</span></p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title mb-10">Shipping</h2>
                                        <p>We offer free shipping within Bangalore Pincode areas.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title mb-10">Courier Services</h2>
                                        <p>We work with reliable courier partners to ensure safe and timely delivery of your products.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title mb-10">Delivery Address</h2>
                                        <p>We do not permit changes to the delivery address after placing an order. Please provide the correct address during checkout.</p>
                                    </div>
                                    <div className="item-details-widget item-details-widget-mb">
                                        <h2 className="item_details_info_title mb-10">Delayed or Undelivered Orders</h2>
                                        <p>If your order is significantly delayed or undelivered, contact our customer support for assistance.</p>
                                        <p>Contact/Whatsapp No<a href=" sales@replicaindia.com" className='fw-bold'> sales@replicaindia.com</a></p>
                                        <p>For any further queries regarding our shipping policies, please feel free to reach out to us.</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <CtaSection cClassNamclassName={'bg'} />
            </div>
            <Footer />
            <Whatsapp />
            <Scrollbar />
        </Fragment>
    )
};
export default PrivacyPage;
