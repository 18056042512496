import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import products from "../../json/products.json";

const CaseStudySection = () => {
  const { slug, type, pageNumber } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeFilter, setActiveFilter] = useState("*");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;
  // Store the current base path (shop or product-category)
  const [currentBasePath, setCurrentBasePath] = useState("shop");

  // Enhanced map for URL paths to filter categories
  const pathToCategoryMap = {
    "": "*",
    "all-brands": "*",
    "cartridge": "Cartridge",
    "drum-unit": "Drum Unit",
    "all-brands/brother": "BROTHER",
    "brother": "BROTHER",
    "all-brands/epson": "EPSON",
    "epson": "EPSON",
    "all-brands/xerox": "XEROX",
    "xerox": "XEROX",
    "xerox-black-and-white-a4": "XEROX"
  };

  // Enhanced map for categories to URL paths
  const categoryToPathMap = {
    "*": "all-brands",
    "Cartridge": "cartridge",
    "Drum Unit": "drum-unit",
    "BROTHER": "all-brands/brother",
    "EPSON": "all-brands/epson",
    "XEROX": "all-brands/xerox"
  };

  useEffect(() => {
    // Parse the full path to determine active filter and base path
    parsePath(location.pathname);
  }, [location.pathname]);

  // Function to parse the path and set appropriate state values
  const parsePath = (path) => {
    // Determine the base path
    let basePath = "shop";
    if (path.startsWith("/product-category/") || path.startsWith("/product-details/")) {
      basePath = path.startsWith("/product-category/") ? "product-category" : "product-details";
    }
    setCurrentBasePath(basePath);
    
    // Remove the base path part
    let pathWithoutBase = path.replace(`/${basePath}/`, '');
    
    // Handle pagination part
    let page = 1;
    const pageRegex = /page\/(\d+)/;
    const pageMatch = pathWithoutBase.match(pageRegex);
    
    if (pageMatch) {
      page = parseInt(pageMatch[1], 10);
      // Remove the pagination part from the path
      pathWithoutBase = pathWithoutBase.replace(`page/${page}`, '').replace(/\/+$/, '');
    }
    setCurrentPage(page);
    
    // Special case for just "shop/"
    if (path === `/${basePath}/` || path === `/${basePath}`) {
      setActiveFilter("*");
      return;
    }
    
    // Special case for "shop/all-brands" or "shop/all-brands/"
    if (pathWithoutBase === "all-brands" || pathWithoutBase === "all-brands/") {
      setActiveFilter("*");
      return;
    }
    
    // Handle the category part (remove trailing slashes)
    pathWithoutBase = pathWithoutBase.replace(/\/+$/, '');
    
    // Find the matching category for the path
    let foundCategory = "*";
    
    // First check direct matches
    if (pathToCategoryMap[pathWithoutBase]) {
      foundCategory = pathToCategoryMap[pathWithoutBase];
    } 
    // Then check for path segments matching our map
    else {
      // Split the path and try different combinations
      const segments = pathWithoutBase.split('/');
      
      // Try the last segment first
      if (pathToCategoryMap[segments[segments.length - 1]]) {
        foundCategory = pathToCategoryMap[segments[segments.length - 1]];
      }
      // Try full path
      else if (segments.length > 1) {
        const possiblePaths = [];
        
        // Try different combinations of segments
        for (let i = 0; i < segments.length; i++) {
          for (let j = i + 1; j <= segments.length; j++) {
            possiblePaths.push(segments.slice(i, j).join('/'));
          }
        }
        
        // Check each possible path against our map
        for (const possiblePath of possiblePaths) {
          if (pathToCategoryMap[possiblePath]) {
            foundCategory = pathToCategoryMap[possiblePath];
            break;
          }
        }
      }
    }
    
    setActiveFilter(foundCategory);
  };

  useEffect(() => {
    let result;
    if (activeFilter === "*") {
      result = products;
    } else {
      result = products.filter(product => {
        if (!product.categories) return false;
        
        // Check if any of the product's categories match the active filter
        return product.categories.some(category => {
          // Direct match by category name
          if (category.name === activeFilter) return true;
          
          // For brand categories (XEROX, EPSON, BROTHER), also match by partial name or URL
          if (activeFilter === "XEROX" && (
              category.name === "XEROX" || 
              category.name.includes("Xerox") ||
              (category.url && category.url.toLowerCase().includes("/xerox/"))
          )) {
            return true;
          }
          
          if (activeFilter === "EPSON" && (
              category.name === "EPSON" || 
              category.name.includes("EPSON") ||
              (category.url && category.url.toLowerCase().includes("/epson/"))
          )) {
            return true;
          }
          
          if (activeFilter === "BROTHER" && (
              category.name === "BROTHER" || 
              category.name.includes("Brother") ||
              (category.url && category.url.toLowerCase().includes("/brother/"))
          )) {
            return true;
          }
          
          if (activeFilter === "Cartridge" && (
              category.name === "Cartridge" ||
              category.name.includes("Cartridge") ||
              (category.url && category.url.toLowerCase().includes("/cartridge/"))
          )) {
            return true;
          }
          
          if (activeFilter === "Drum Unit" && (
              category.name === "Drum Unit" ||
              category.name.includes("Drum") ||
              (category.url && category.url.toLowerCase().includes("/drum-unit/"))
          )) {
            return true;
          }
          
          return false;
        });
      });
    }
    setFilteredProducts(result);
  }, [activeFilter]);

  const handleFilter = (category) => {
    const targetPath = categoryToPathMap[category];
    
    setCurrentBasePath("product-category")
    if (category === "*") {
      navigate(`/${"product-category"}/all-brands`);
    } else {
      navigate(`/${"product-category"}/${targetPath}`);
    }
    
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    
    // Get current category path
    let categoryPath = activeFilter === "*" ? "all-brands" : categoryToPathMap[activeFilter];
    
    if (page === 1) {
      if(currentBasePath === "shop"){
        navigate(`/${currentBasePath}`);

      } else{

        navigate(`/${currentBasePath}/${categoryPath}`);
      }
    } else {
      if(currentBasePath === "shop"){
        navigate(`/${currentBasePath}/page/${page}`);

      } else{

        navigate(`/${currentBasePath}/${categoryPath}/page/${page}`);
      }
    }
    
    setCurrentPage(page);
    window.scrollTo(10, 0);
  };

  // Pagination
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const getPaginationRange = () => {
    const range = [];
    const maxVisiblePages = 5;
    
    if (totalPages <= maxVisiblePages) {
      // Show all pages if there are fewer than maxVisiblePages
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      // Show a subset of pages with current page in the middle if possible
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
      let endPage = startPage + maxVisiblePages - 1;
      
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }
      
      for (let i = startPage; i <= endPage; i++) {
        range.push(i);
      }
    }
    
    return range;
  };

  // Get current products
  const currentProducts = filteredProducts.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage
  );

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  // Filters for UI
  const filters = [
    { key: "*", label: "View All" },
    { key: "Cartridge", label: "Cartridge" },
    { key: "Drum Unit", label: "Drum Unit" },
    { key: "BROTHER", label: "BROTHER" },
    { key: "EPSON", label: "EPSON" },
    { key: "XEROX", label: "XEROX" }
  ];

  return (
    <section className="casestudy pt-70 pb-130" style={{ backgroundColor: "#f6f6f8" }}>
      <div className="container">
        {/* Filter Buttons */}
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="team-menu casestudy-menu">
              {filters.map((filter, index) => (
                <button
                  key={index}
                  className={activeFilter === filter.key ? "active" : ""}
                  onClick={() => handleFilter(filter.key)}
                >
                  {filter.label}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Case Studies Grid */}
        <div className="casestudy-content mt-70">
          <div className="row grid mt-none-30">
            <AnimatePresence>
              {currentProducts.map((product, index) => (
                <motion.div
                  key={index}
                  className={`col-md-4 grid-item mt-30`}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="casestudy-item">
                    <div className="casestudy-img">
                      <Link onClick={ClickHandler} to={`/product/${product.slug}`}>
                        {product.images && product.images.length > 0 && (
                          <img 
                            className="height50 width-100%" 
                            src={`${process.env.PUBLIC_URL}/${product.images[0].src}`} 
                            alt={product.images[0].alt || product.title} 
                          />
                        )}
                      </Link>
                      <div className="content_wrap">
                        <h3 className="item_title">{product.title}</h3>
                      </div>
                    </div>
                    <Link onClick={ClickHandler} to={`/product/${product.slug}`} className="xb-overlay"></Link>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <ul className="blog-pagination ul_li">
              <li
                onClick={() => handlePageChange(currentPage - 1)}
                className={`pagination-btn ${currentPage === 1 ? 'disabled' : ''}`}
              >
                <i className="fas fa-chevron-double-left"></i>
              </li>
              
              {getPaginationRange().map(number => (
                <li
                  onClick={() => handlePageChange(number)}
                  key={number} 
                  className={`pagination-btn ${currentPage === number ? "active" : ""}`}
                >
                  <a>{number}</a>
                </li>
              ))}
              
              <li
                onClick={() => handlePageChange(currentPage + 1)}
                className={`pagination-btn ${currentPage === totalPages ? 'disabled' : ''}`}
              >
                <i className="fas fa-chevron-double-right"></i>
              </li>
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default CaseStudySection;