import React from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import sIcon1 from "../../images/icon/ser-01.svg";
import brand1 from "../../images/award/epson/1.jpg";
import brand2 from "../../images/award/epson/2.jpg";
import brand3 from "../../images/award/epson/3.jpg";
import brand4 from "../../images/award/epson/4.jpg";
import brand5 from "../../images/award/epson/5.jpg";
import brand6 from "../../images/award/epson/6.jpg";
import brand7 from "../../images/award/epson/7.jpg";
import brand8 from "../../images/award/epson/8.jpg";
import brand9 from "../../images/award/epson/9.jpg";
import brand10 from "../../images/award/epson/10.jpg";

const EpsonImagesSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <section className="award" style={{ backgroundColor: "#f6f6f8" }}>
      <div className="container">
        <div className="row mt-none-30 align-items-center">
          <div className="col-xl-6 col-lg-5 mt-30">
            <div className="service-info ab-award-content pt-100">
              <div className="sec-title--two">
                <Fade
                  direction="down"
                  triggerOnce={"false"}
                  duration={1000}
                  delay={9}
                >
                  <div>
                    <span
                      className="sub-title wow fadeInDown"
                      data-wow-duration="600ms"
                    >
                      <img src={sIcon1} alt="" />
                      Epson
                    </span>
                  </div>
                </Fade>
                <Fade
                  direction="up"
                  triggerOnce={"false"}
                  duration={1200}
                  delay={9}
                >
                  <div>
                    <h2
                      className="title wow skewIn"
                      data-wow-delay="100ms"
                      data-wow-duration="600ms"
                    >
                      Get the Best Epson Printers for Your Business Needs
                    </h2>
                  </div>
                </Fade>
                <Fade
                  direction="up"
                  triggerOnce={"false"}
                  duration={1200}
                  delay={9}
                >
                  <div>
                    <p
                      className="content wow fadeInUp"
                      data-wow-delay="300ms"
                      data-wow-duration="600ms"
                    >
                      Looking for high-quality and efficient Epson printers?
                      Replica Xerography offers a wide range of Epson printing
                      solutions, including inkjet, laser, and multifunction
                      printers designed for superior performance and
                      cost-effectiveness. Whether you need a printer for home,
                      office, or commercial use, our experts will help you find
                      the perfect model. Fill out the enquiry form today to get
                      the best deals and professional support for your
                      Epson printer needs!.
                    </p>
                  </div>
                </Fade>
              </div>
              <Fade
                direction="up"
                triggerOnce={"false"}
                duration={1200}
                delay={9}
              >
                <div>
                  <div
                    className="xb-btn mt-50 wow fadeInUp view-more-btn"
                    data-wow-delay="450ms"
                    data-wow-duration="600ms"
                  >
                    <Link
                      to="/contact-us"
                      onClick={ClickHandler}
                      className="thm-btn thm-btn--aso thm-btn--aso_yellow view-btn-size"
                    >
                      Enquire Now
                    </Link>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 mt-30">
            <div className="ap-award-wrap ul_li">
              <div className="ap-award-inner marquee-first">
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand1} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand2} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand3} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand1} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand2} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand3} alt="" />
                  </div>
                </div>
              </div>
              <div className="ap-award-inner marquee-2">
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand4} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand5} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand6} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand7} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand4} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand5} alt="" />
                  </div>
                </div>
              </div>
              <div className="ap-award-inner marquee-first marquee-last">
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand8} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand9} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand10} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand8} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand9} alt="" />
                  </div>
                </div>
                <div className="ap-award-item">
                  <div className="xb-img">
                    <img src={brand10} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EpsonImagesSection;
