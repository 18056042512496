import rental_service from "../images/service/Rental-Services.jpg";
import manage_printService from "../images/service/manage-printService.jpg";
import in_house from "../images/service/in-house.jpg";
import amc from "../images/service/amc.jpg";
import fsma from "../images/service/fsma.jpg";
import sImg1 from "../images/icon/sd-icon01.svg";
import sImg2 from "../images/icon/sd-icon02.svg";
import sImg3 from "../images/icon/sd-icon03.svg";
import sImg4 from "../images/icon/sd-icon04.svg";

const Services = [
  {
    Id: "1",
    sImg: rental_service,
    title: "Rental Solutions",
    slug: "printer-rental-service",
    thumb1: "Strategy",
    thumb2: "Consultation",
    description:
      "Replica, your trusted partner in delivering unparalleled printer rental solutions, ensures seamless printing solutions tailored to your business needs.",
    pagedescription:
      "Replica, your trusted partner in delivering unparalleled printer rental solutions. Designed to meet the unique needs of corporate entities, educational institutions, and hospitals, our offerings provide a gateway to enhanced productivity, streamlined operations, and cost-effective solutions.",
    getAQuoteText:
      "For Print Management Software Solutions and Rental Solutions ! Enquire Below",
    heading1:
      "Unleash Efficiency with World-Class Printers and Multi-function Devices",
    description1:
      "At Replica, we understand that excellence starts with the right tools. That’s why we offer an extensive selection of top-of-the-line printers, copiers, and multi-function devices, all meticulously curated to elevate your organization’s performance. From lightning-fast printers for high-volume document production to multi-function devices equipped with copy, print, scan, Wi-Fi, and duplex capabilities, we provide the technology you need to stay ahead.",
    heading2: "Seamless Integration, Hassle-Free Service",
    description2:
      "When you choose our printer rental solutions, you gain a single point of contact for all your printing needs. Our dedicated team ensures a seamless integration of our equipment into your existing infrastructure, allowing you to hit the ground running. From setup and installation to ongoing maintenance and support, we handle it all, freeing your organization from the complexities of printer management. Experience peace of mind knowing that our experts are just a phone call away, ready to provide prompt and reliable assistance whenever you need it.",
    heading3: "Elevate Productivity, Reduce Costs",
    description3:
      "Renting printers from Replica offers a host of advantages that empower your organization to thrive:",
    lists: [
      {
        title: "Cost Optimization:",
        description:
          "Our flexible rental plans eliminate the need for substantial upfront investments. Instead, you can allocate your resources strategically, paying a convenient monthly fee that aligns with your budgetary requirements.",
      },
      {
        title: "Scalability:",
        description:
          "As your organization’s needs evolve, so do your printing requirements. With our rental solutions, you have the freedom to scale your printing capacity up or down, ensuring that you have the right equipment precisely when you need it. Effortlessly handle short-term projects, seasonal fluctuations, or expansion plans.",
      },
      {
        title: "State-of-the-Art Technology:",
        description:
          "Stay at the forefront of printing innovation without the worry of obsolescence. Our rental fleet features the latest advancements in printing technology, empowering your organization with cutting-edge features, increased efficiency, and superior print quality.",
      },
      {
        title: "Streamlined Maintenance and Support:",
        description:
          "Say goodbye to the headaches of printer maintenance and troubleshooting. Our expert team proactively manages the upkeep of your rented equipment, ensuring optimal performance at all times. Focus on your core activities while we handle firmware updates, repairs, and technical support, enabling your team to operate at peak efficiency.",
      },
      {
        title: "Enhanced Productivity:",
        description:
          "Our top-of-the-line printers and multi-function devices are built to drive productivity. Experience swift print speeds, document handling, and advanced features that streamline your workflow. With our rental solutions, you can significantly reduce printing time, increase document quality, and achieve greater operational efficiency.",
      },
    ],
    heading4: "Partner with the Experts in Printer Rentals",
    description4:
      "Join the ranks of leading corporate entities, educational institutions, and hospitals that have harnessed the power of our printer rental solutions. At Replica, we are committed to delivering exceptional solutions tailored to your unique needs, enabling you to focus on what truly matters accelerating success and serving your stakeholders with excellence. Contact us today to explore our range of printer rental options and embark on a transformative printing journey with Replica. Experience efficiency, cost-effectiveness, and unparalleled support as we unlock new levels of productivity for your organization.",
    ourProcessDescription:
      "At Replica Xerography, we make printer rentals simple and hassle-free. From selection to setup and support, our flexible plans ensure you get the perfect printing solution for your needs.",
    process: [
      {
        img: sImg1,
        title: "Consultation & Requirement",
        description:
          "Tell us your printing needs, and our experts will recommend the best printer for your business.",
      },
      {
        img: sImg2,
        title: "Choose Your Rental Plan",
        description:
          "Select from flexible short-term or long-term rental plans that fit your budget and usage.",
      },
      {
        img: sImg3,
        title: "Delivery & Setup",
        description:
          "We deliver, install, and configure the printer at your location for a hassle-free experience.",
      },
      {
        img: sImg4,
        title: "Ongoing Support & Maintenance",
        description:
          "Enjoy seamless printing with regular maintenance, technical support, and quick issue resolution.",
      },
    ],
    whyChooseTitle:
      "Why Choose Replica Xerography for Printer Rental Solutions?",
    whyChooseDescription:
      "Get reliable, affordable, and hassle-free printer rentals designed for your needs. Enjoy smooth printing with high-quality equipment and expert support.",
    chooseFirstLists: [
      "Wide Range of Printers.",
      "Flexible Rental Plans.",
      "Affordable Pricing.",
    ],
    chooseSecondLists: [
      "Quick Delivery & Setup.",
      "Regular Maintenance & Support.",
      "Upgrade Anytime.",
    ],
  },
  {
    Id: "2",
    sImg: manage_printService,
    title: "Print Management Software Solutions",
    slug: "managed-print-services",
    thumb1: "Management",
    thumb2: "Transfer",
    description:
      "Replica offers Print Management Software Solutions (MPS) that cater to the printing needs of educational institutions, corporates, and various industries.",
    pagedescription:
      "Replica offers Print Management Software Solutions (MPS) that cater to the printing needs of educational institutions, corporates, and various industries. MPS is a comprehensive approach to managing printing resources, optimizing efficiency, and reducing costs.",
    getAQuoteText: "For Print Management Software Solutions ! Enquire Below",
    heading1: "Features",
    lists: [
      {
        title: "Secured Printing",
        description: "",
      },
      {
        title: "Collect prints on any printer",
        description: "",
      },
      {
        title: "Set limit for users",
        description: "",
      },
      {
        title: "User selective Color prints",
        description: "",
      },
      {
        title: "Block scan and copy in Secured Departments",
        description: "",
      },
      {
        title: "Selective data scan",
        description: "",
      },
      {
        title: "Reports user/department/unit-wise",
        description: "",
      },
      {
        title: "Accounting",
        description: "",
      },
    ],
    description4:
      "Replica’s Print Management Software Solutions offer numerous advantages for educational institutions, corporates, and various industries. These benefits include cost savings, enhanced efficiency, tailored printing solutions, scalability, proactive maintenance and support, data security and compliance, as well as a commitment to sustainability. By partnering with Replica Xerography, organizations can optimize their printing processes, reduce costs, and focus on their core objectives while enjoying reliable and efficient print management services.",
    ourProcessDescription:
      "We make printing hassle-free with a streamlined process. From assessing your needs to optimizing costs and providing ongoing support, our managed print services ensure smooth, efficient, and cost-effective printing for your business.",
    process: [
      {
        img: sImg1,
        title: "Assessment & Consultation",
        description:
          "We analyze your printing needs, current usage, and costs to create a customized plan.",
      },
      {
        img: sImg2,
        title: "Implementation & Optimization",
        description:
          "We set up and integrate efficient print solutions to reduce waste and improve productivity.",
      },
      {
        img: sImg3,
        title: "Monitoring & Maintenance",
        description:
          "Our team provides real-time monitoring, regular maintenance, and proactive issue resolution.",
      },
      {
        img: sImg4,
        title: "Cost Management & Support",
        description:
          "We track usage, optimize expenses, and offer continuous support for seamless printing.",
      },
    ],
    whyChooseTitle: "Why Choose Us for Print Management Software Solutions?",
    whyChooseDescription:
      "We provide cost-effective, efficient, and hassle-free managed print solutions tailored to your business needs. Our services help you save time, reduce costs, and improve productivity with expert support.",
    chooseFirstLists: [
      "Customized Solutions.",
      "Cost Savings.",
      "Seamless Integration.",
    ],
    chooseSecondLists: [
      "Proactive Maintenance.",
      "Enhanced Security.",
      "Dedicated Support.",
    ],
  },
  {
    Id: "3",
    sImg: in_house,
    title: "In-House Documentation",
    slug: "in-house-documentation",
    thumb1: "Landing Page",
    thumb2: "Plugins",
    description:
      "At Replica, we are the masters of turning your visions into tangible reality through our awe-inspiring in-house documentation services.",
    pagedescription:
      "At Replica, we are the masters of turning your visions into tangible reality through our awe-inspiring in-house documentation services. With an unwavering commitment to perfection and an arsenal of cutting-edge technology, we have become the preferred choice for large corporates, industries, schools, and a myriad of other industries seeking stunning documentation solutions that captivate the senses.",
    getAQuoteText: "For In-House DocumentationService ! Enquire Below",
    heading1: "Elevate Your Brand with Exquisite Prints",
    description1:
      "Step into a realm where creativity knows no bounds. Our in-house documentation services encompass a breathtaking array of masterfully crafted materials. From exquisite business cards that exude sophistication to captivating courseware books that inspire knowledge, we breathe life into your ideas with unrivaled finesse. Our repertoire also includes mesmerizing booklet makers, alluring cover posters, irresistible brochures, vibrant flyers, flawless creasers, precision-cutters, seamless trimmers, immaculate folders, and flawlessly bound spiral, ring, and perfect binders. Elevate your brand’s image and make a lasting impact with our extraordinary prints.",
    heading2: "Swift Solutions for Time-Critical Projects",
    description2:
      "In the fast-paced world of business, timing is everything. We understand the urgency of your projects and thrive on delivering excellence with utmost efficiency. Our streamlined processes and agile workflows ensure swift execution, allowing you to meet even the most demanding deadlines without compromising on quality. With Replica by your side, your time-critical projects will come to life seamlessly, leaving you free to focus on what truly matters – achieving your goals.",
    heading3: "Unmatched Quality that Mesmerizes",
    description3:
      "We believe that true beauty lies in the details. Equipped with state-of-the-art technology and backed by a team of seasoned experts, we bring unrivaled quality to every project we undertake. Each print is a testament to our unwavering dedication to perfection, leaving no room for compromise. Whether it’s the impeccable precision of our cuts, the vibrant hues that leap off the page, or the flawless lamination that adds that extra touch of elegance, our documentation services redefine the standards of excellence.",
    lists: [],
    heading4: "Empowering Industries, Schools, and Visionaries",
    description4:
      "Our stellar reputation extends far and wide, as we have proudly served as a trusted partner to a diverse range of industries, schools, and corporates. From empowering businesses to strengthen their brand presence with captivating marketing collateral to providing educational institutions with engaging materials that nurture young minds, we tailor our services to meet your unique needs. Join the ranks of those who have experienced the transformative power of our documentation solutions and unleash the full potential of your ideas.",
    heading5: "Discover the Artistry of In-House Documentation",
    description5:
      "Embrace the unparalleled craftsmanship of Replica. As pioneers of innovation and creators of enchanting prints, we invite you to witness the magic of our in-house documentation services. Let your brand soar to new heights with our captivating creations that leave a lasting impression on all who lay eyes upon them. Contact us today and embark on a journey of unrivaled creativity, precision, and excellence. Together, we will redefine the very essence of in-house documentation and unveil a world of infinite possibilities.",
    process: [
      {
        img: sImg1,
        title: "Assessment & Planning",
        description:
          "We analyze your organization's printing needs, evaluate current usage, and design a tailored MPS solution to optimize efficiency and reduce costs.",
      },
      {
        img: sImg2,
        title: "Implementation & Integration",
        description:
          "We deploy secured printing solutions, set user-based limits, enable selective color printing, and integrate seamless printing management across all departments.",
      },
      {
        img: sImg3,
        title: "Monitoring & Maintenance",
        description:
          "Our team provides proactive maintenance, real-time monitoring, and detailed reporting to ensure smooth, uninterrupted printing operations.",
      },
      {
        img: sImg4,
        title: "Security & Optimization",
        description:
          "We enhance data security, enforce compliance measures, and continuously optimize printing workflows to maximize efficiency and sustainability.",
      },
    ],
    whyChooseTitle:
      "Why Choose Replica Xerography for In-House Documentation Services?",
    whyChooseDescription:
      "We offer secure, affordable, and efficient in-house documentation solutions designed for your business. Our services help you manage documents better, improve workflow, and boost productivity.",
    chooseFirstLists: [
      "Customized Solutions.",
      "Improved Efficiency.",
      "Enhanced Security.",
    ],
    chooseSecondLists: [
      "Cost Savings.",
      "User-Friendly Integration.",
      "Reliable Maintenance & Support.",
    ],
  },
  {
    Id: "4",
    sImg: amc,
    title: "AMC-Annual Maintenance Contract",
    slug: "annual-maintenance-contract",
    thumb1: "Consultation",
    thumb2: "solution",
    description:
      "AMC with Replica for Xerox printers ensures reliable service, provides cost savings, delivers expert assistance...",
    pagedescription:
      "AMC with Replica for Xerox printers ensures reliable service, minimizes downtime, provides cost savings, delivers expert assistance, utilizes genuine parts, includes regular maintenance and timely repairs, offers priority service, enhances productivity, provides long-term support, and brings peace of mind to your printing operations.",
    getAQuoteText: "For AMC-Annual Maintenance Contract ! Enquire Below",
    lists: [],
    ourProcessDescription:
      "Replica Xerography’s Annual Maintenance Contract (AMC) keeps your printing equipment running smoothly with regular servicing, quick repairs, and expert support. Ensure hassle-free maintenance and uninterrupted performance with our reliable AMC services!.",
    process: [
      {
        img: sImg1,
        title: "Consultation & Agreement",
        description:
          "We assess your equipment, understand your maintenance needs, and create a customized AMC plan.",
      },
      {
        img: sImg2,
        title: "Scheduled Maintenance & Servicing",
        description:
          "Regular check-ups and preventive maintenance ensure your machines run smoothly without interruptions.",
      },
      {
        img: sImg3,
        title: "Quick Repairs & Support",
        description:
          "Our team provides fast troubleshooting and on-site repairs to minimize downtime.",
      },
      {
        img: sImg4,
        title: "Performance Monitoring & Upgrades",
        description:
          "We track system performance and suggest upgrades or improvements for better efficiency.",
      },
    ],
    whyChooseTitle: "Why Choose Us for AMC – Annual Maintenance Contract?",
    whyChooseDescription:
      "Replica Xerography offers reliable, cost-effective, and hassle-free AMC services to keep your printing equipment in top condition. Our expert maintenance ensures smooth operations with minimal downtime.",
    chooseFirstLists: [
      "Regular Maintenance.",
      "Quick Repairs.",
      "Cost-Effective Solutions.",
    ],
    chooseSecondLists: [
      "Genuine Spare Parts.",
      "Expert Support.",
      "Increased Efficiency.",
    ],
  },
  {
    Id: "5",
    sImg: fsma,
    title: "FSMA-Full Service and Maintenance Contract",
    slug: "fsma-full-service-and-maintenance-agreement",
    thumb1: "Website",
    thumb2: "Mobile App",
    description:
      "A Full Service and Maintenance Agreement with Replica for Xerox Printers offers comprehensive support...",
    pagedescription:
      "A Full Service and Maintenance Agreement with Replica for Xerox Printers offers comprehensive support, including service, spares, and consumables. With this agreement, you benefit from hassle-free maintenance as we take care of sourcing and providing genuine parts and consumables, ensuring optimal performance and compatibility. The agreement provides cost predictability, allowing you to accurately budget for your printer maintenance needs without unexpected expenses. Timely replacements of spares and consumables minimize printer downtime and ensure uninterrupted operations. With expert technicians, priority service, and a long-term partnership, you can rely on us to deliver efficient service, keeping your Xerox printers performing at their best.",
    getAQuoteText:
      "For FSMA-Full Service and Maintenance Contract ! Enquire Below",
    lists: [],
    ourProcessDescription:
      "At Replica Xerography, our Full Service and Maintenance Agreement (FSMA) ensures your Xerox printers run smoothly with regular servicing, genuine parts, and timely replacements. We provide cost-effective, hassle-free maintenance with expert support, minimizing downtime and keeping your operations uninterrupted.",
    process: [
      {
        img: sImg1,
        title: "Agreement & Custom Plan",
        description:
          "We assess your Xerox printer needs and create a customized FSMA plan covering service, spares, and consumables.",
      },
      {
        img: sImg2,
        title: "Hassle-Free Maintenance & Repairs",
        description:
          "Our expert technicians provide regular maintenance, servicing, and quick repairs to keep your printers running smoothly.",
      },
      {
        img: sImg3,
        title: "Genuine Parts & Timely Replacements",
        description:
          "We source and supply original spares and consumables to ensure optimal performance and minimize downtime.",
      },
      {
        img: sImg4,
        title: "Cost Predictability & Priority Support",
        description:
          "With fixed costs and priority service, you get uninterrupted printing without unexpected expenses.",
      },
    ],
    whyChooseTitle:
      "Why Choose Us for FSMA – Full Service and Maintenance Agreement?",
    whyChooseDescription:
      "Replica Xerography offers hassle-free, cost-effective, and reliable FSMA services to keep your Xerox printers in top condition. With our expert support, genuine parts, and timely maintenance, we ensure smooth and uninterrupted printing.",
    chooseFirstLists: [
      "Complete Coverage.",
      "Original Parts & Supplies.",
      "Regular Maintenance.",
    ],
    chooseSecondLists: [
      "Fast Repairs & Support.",
      "Fixed Costs, No Surprises.",
      "Skilled Technicians.",
    ],
  },
];

export default Services;
