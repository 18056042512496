import React, { Fragment } from 'react';
import CaseStudySection from './CaseSection';
import Header from '../../components/header/Header';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import SEOHelmet from '../router/SEOHelmet';
import Whatsapp from '../../components/common/Whatsapp';


const CaseStudySingle = (props) => {

    return (
        <Fragment>
             <SEOHelmet slug={"shop"}/>
            <div className='body_wrap sco_agency'>
                <Header />
                <div className=' pt-150'>

                <CaseStudySection />
                <CtaSection />
                </div>
            </div>
            <Footer />
            <Whatsapp />
            <Scrollbar />
        </Fragment>
    )
};
export default CaseStudySingle;
