import pImg1 from '../images/testimonial/ASD.png'
import pImg2 from '../images/testimonial/AM-C400.png'
import pImg3 from '../images/testimonial/A4-BW.png'

const Project = [
    {
        Id: '1',
        pImg: pImg1,
        title: 'Brother Printers',
        slug: 'brother',
        rnumber: '300',
        rtext: 'Businesses use Brother printers.',
        tnumber: '3,000',
        ttext: 'Brother Printers in operation.',
        snumber: '30',
        stext: 'Cost Savings with efficient print management.',
        description: 'Compact and cost-effective printers designed for efficiency and durability.',
    },
    {
        Id: '2',
        pImg: pImg2,
        title: 'Epson Printers',
        slug: 'epson',
        rnumber: '200',
        rtext: 'Businesses trust Epson printers.',
        tnumber: '2,000',
        ttext: 'Epson Printers installed.',
        snumber: '99',
        stext: 'Print Quality Satisfaction among users.',
        description: 'Innovative inkjet and laser printers for vibrant, high-quality prints.',
    },
    {
        Id: '3',
        pImg: pImg3,
        title: 'Xerox Printers',
        slug: 'xerox',
        rnumber: '500',
        rtext: 'Businesses rely on Xerox printers.',
        tnumber: '5,000',
        ttext: 'Xerox Printers deployed.',
        snumber: '98',
        stext: 'Uptime with expert servicing.',
        description: 'Reliable and high-performance printing solutions for businesses of all sizes.',
    },
]

export default Project;