import { useRef, useEffect, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar';
import icon1 from '../../images/icon/prev-icon.png';
import icon2 from '../../images/icon/next-icon.png';

const BlogList = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    
    const { page: pageParam, category: categoryParam } = useParams();

    const blogs = require("../../json/blogs.json");
    
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [activeCategory, setActiveCategory] = useState(null);
    const blogsPerPage = 10;

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const page = pageParam ? parseInt(pageParam) : 1;
        const category = categoryParam || null;
        const tag = queryParams.get('tag');
        const searchQuery = queryParams.get('search');
        
        setActiveCategory(category);
        let filtered = [...blogs];
        
        if (category) {
            filtered = filtered.filter(blog => blog.category.toLowerCase().replace(/\s+/g, '-') === category);
        }
        
        if (tag) {
            filtered = filtered.filter(blog => 
                blog.category.toLowerCase().replace(/\s+/g, '-').includes(tag.toLowerCase()) ||
                blog.title.toLowerCase().replace(/\s+/g, '-').includes(tag.toLowerCase()) ||
                blog.short_description.toLowerCase().replace(/\s+/g, '-').includes(tag.toLowerCase())
            );
        }
        
        if (searchQuery) {
            filtered = filtered.filter(blog => 
                blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                blog.short_description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                blog.category.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        
        // Calculate actual total pages based on filtered results
        const actualTotalPages = Math.max(1, Math.ceil(filtered.length / blogsPerPage));
        
        // Adjust page number if it's beyond the total pages
        const validPage = Math.min(page, actualTotalPages);
        
        setFilteredBlogs(filtered);
        setTotalPages(actualTotalPages);
        setCurrentPage(validPage);
        
        // If page in URL is invalid, update the URL with the valid page
        if (page > actualTotalPages && page !== 1) {
            const newQueryParams = new URLSearchParams(location.search);
            newQueryParams.set('page', validPage.toString());
            navigateToPage(validPage);
            // navigate(`${location.pathname}?${newQueryParams.toString()}`, { replace: true });
        }
    }, [location.search, blogs, navigate]);

    useEffect(() => {
        if (swiperRef.current && prevRef.current && nextRef.current) {
            swiperRef.current.params.navigation.prevEl = prevRef.current;
            swiperRef.current.params.navigation.nextEl = nextRef.current;
            swiperRef.current.navigation.init();
            swiperRef.current.navigation.update();
        }
    }, []);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages || pageNumber === currentPage) {
            return;
        }
        
        navigateToPage(pageNumber);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', pageNumber);
        // navigate(`${location.pathname}?${queryParams.toString()}`);
        window.scrollTo(10, 0);
    };

    const getPaginationRange = () => {
        if (totalPages <= 5) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }
        
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, startPage + 4);
        
        if (endPage - startPage < 4) {
            startPage = Math.max(1, endPage - 4);
        }
        
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    const navigateToPage = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages || pageNumber === currentPage) {
            return; 
        }
        
        let url = '/blogs';
        
        if (activeCategory) {
            url = `/category/${activeCategory}`;
        }
        
        if (pageNumber > 1) {
            url = `${url}/page/${pageNumber}`;
        }
        
        navigate(url);
        window.scrollTo(10, 0);
    };

    const getCategoryUrl = (category) => {
        return `/blogs/category/${category}`;
    };

    return (
        <>

        <div>
            <div className="blog pt-70">
                {currentPage === 1 && (

                <div className="container">
                    {filteredBlogs.length > 0 ? (
                        <div 
           
                        className="blog-slider  swiper-container pos-rel">
                            <Swiper
                                modules={[Navigation]}
                                spaceBetween={50}
                                slidesPerView={1}
                                loop={true}
                                speed={1800}
                                parallax={true}
                                ref={swiperRef}
                                onBeforeInit={(swiper) => {
                                    swiperRef.current = swiper;
                                }}
                            >
                                {filteredBlogs.slice(0, 3).map((blog, Bitem) => (
                                    <SwiperSlide key={Bitem}>
                                        <div
                                        className="blog-slide-item">
                                            <div className="xb-item--img">
                                                <Link onClick={ClickHandler} to={`/${blog.slug}`}>
                                                    <img src={`${process.env.PUBLIC_URL}/${blog.image_path}`} alt={blog.image_alt} />
                                                </Link>
                                            </div>
                                            <div className="xb-item--holder">
                                                <Link onClick={ClickHandler} to={getCategoryUrl(blog.category)} className="xb-item--tag">
                                                    {blog.category}
                                                </Link>
                                                <h2 className="xb-item--title border-effect">
                                                    <Link onClick={ClickHandler} to={`/${blog.slug}`}>
                                                        {blog.title}
                                                    </Link>
                                                </h2>
                                                <p className="xb-item--content">{blog.short_description}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="swiper-pagination"></div>
                            <div className="blog-item_button">
                                <div className="blog-swiper-btn swiper-button-prev" ref={prevRef}>
                                    <img src={icon1} alt="Previous" />
                                </div>
                                <div className="blog-swiper-btn swiper-button-next" ref={nextRef}>
                                    <img src={icon2} alt="Next" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="no-results">
                            <h2>No blogs found matching your criteria</h2>
                            <Link to="/blog" className="thm_btn mt-30">View All Blogs</Link>
                        </div>
                    )}
                </div>
                )}

            </div>
            <section className={`blog_details_section pb-130 ${currentPage === 1 ? "mt-120" : ""} `}>
                <div className="container">
                    <div className="row mt-none-30 g-0 align-items-start">
                        <div className="col-lg-8 mt-30">
                            <div className="blog_details_content">
                                {currentBlogs.length > 0 && (
                                    currentBlogs.map((blog, Bitem) => (
                                        <div className="blog_details_item ul_li" key={Bitem}>
                                            <div className="xb-item--img">
                                                <Link onClick={ClickHandler} to={`/${blog.slug}`}>
                                                    <img src={`${process.env.PUBLIC_URL}/${blog.image_path}`} alt={blog.image_alt} />
                                                </Link>
                                            </div>
                                            <div className="xb-item--holder">
                                                <span className="xb-item--text">{blog.category}</span>
                                                <h3 className="xb-item--title border-effect">
                                                    <Link onClick={ClickHandler} to={`/${blog.slug}`}>
                                                        {blog.title}
                                                    </Link>
                                                </h3>
                                                <span className="xb-item--content">{blog.short_description}</span>
                                                <div className="xb-item--button mt-50">
                                                    <Link onClick={ClickHandler} to={`/${blog.slug}`}>
                                                        Read more <i className="far fa-arrow-right"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                                
                                {/* Add a debug div to show actual counts */}
                                <div className="debug-info" style={{display: 'none'}}>
                                    <p>Total blogs: {blogs.length}</p>
                                    <p>Filtered blogs: {filteredBlogs.length}</p>
                                    <p>Total pages: {totalPages}</p>
                                    <p>Current page: {currentPage}</p>
                                </div>
                                
                                {totalPages > 1 && (
                                    <ul className="blog-pagination ul_li">
                                        <li
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            className={`pagination-btn ${currentPage === 1 ? 'disabled' : ''}`}
                                        >
                                            <i className="fas fa-chevron-double-left"></i>
                                        </li>
                                        
                                        {getPaginationRange().map(number => (
                                            <li
                                                onClick={() => handlePageChange(number)}
                                                key={number} 
                                                className={`pagination-btn ${currentPage === number ? "active" : ""}`}
                                            >
                                                <a>{number}</a>
                                                
                                            </li>
                                        ))}
                                        
                                        <li
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            className={`pagination-btn ${currentPage === totalPages ? 'disabled' : ''}`}
                                        >
                                            <i className="fas fa-chevron-double-right"></i>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                        <BlogSidebar blogs={blogs} />
                    </div>
                </div>
            </section>
        </div>
        </>
    );
};

export default BlogList;