import React, { Fragment, useState } from "react";
import Header from "../../components/header/Header";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import CtaSection from "../../components/CtaSection/CtaSection";
import Bg from "../../images/bg/page_bg01.jpg";
import XeroxImg1 from "../../images/feature/xerox-versalink-c1700.png";
import XeroxImg2 from "../../images/feature/xerox-versalink-b1700.png";
import XeroxImg3 from "../../images/feature/xerox-altalink-c8200.png";
import XeroxImg4 from "../../images/feature/xerox-altalink-b8200.png";
import icon from "../../images/icon/ser-01.svg";
import { AnimatePresence, motion } from "framer-motion";
import SEOHelmet from "../router/SEOHelmet";
import { useLocation } from "react-router-dom";
import Whatsapp from "../../components/common/Whatsapp";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";

const XeroxPrinters = () => {
  const slug = useLocation().pathname.slice(1);
  console.log("slug_slug", slug);
  const [isOpen, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const modalVariants = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
        duration: 0.5,
      },
    },
    exit: {
      y: "-100vh",
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  const [isLoading, setIsLoading] = useState(false);

  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    company: "",
    message: "",
  });

  const [validator] = useState(
    new SimpleReactValidator({ className: "errorMessage" })
  );

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      try {
        const response = await fetch("https://replicaindia.com/contact.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(forms),
        });

        if (response.ok) {
          const data = await response.json();

          if (data.status === "success") {
            setForms({
              name: "",
              email: "",
              subject: "",
              phone: "",
              company: "",
              message: "",
            });
            setIsLoading(false);
            toast.success("Successfully we got your info.");
          } else {
            console.error("Error:", data.message);
            toast.error(data.message);
          }
        } else {
          console.error("Error:", response.statusText);
          toast.error("An error occurred. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("An error occurred. Please try again later.");
      }
    } else {
      validator.showMessages();
    }
  };

  return (
    <Fragment>
      <SEOHelmet slug={slug} />
      <div className="sco_agency">
        <Header />
        <main className="page_content service-single-page">
          <section
            className="page-title pt-200 pos-rel bg_img"
            style={{ backgroundImage: `url(${Bg})` }}
          >
            <div className="container">
              <div className="page-title-wrap">
                <div className="row mt-none-30 align-items-end">
                  <div className="col-xl-8 col-lg-8 mt-30">
                    <div className="page-title-box">
                      <span className="sub-title">
                        <img src={icon} alt="" /> Xerox Printers
                      </span>
                      <h2 className="title">
                        Rent or Buy Xerox Printers for advanced digital printing
                      </h2>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 mt-30">
                    <div className="count-box">
                      <h2 className="number">
                        20 <span className="suffix">+</span>
                      </h2>
                      <span className="text">Years of experience</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="page-title pt-50 pos-rel bg_img"
            style={{ backgroundImage: `url(${Bg})` }}
          >
            <div className="container">
              <div className="page-title-wrap sd-title-wrap">
                <div className="row mt-none-30 ">
                  <div className="col-lg-9 mt-30">
                    <div className="page-title-box">
                      <h1 className="pb-5">Versalink</h1>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <img class="xerox-image" src={XeroxImg1} alt="" />
                  </div>
                  <div class="col-md-6">
                    <h2 className="xerox-printers-title">
                      Xerox VersaLink C7100 Series Colour Multifunction Printers
                    </h2>
                    <p className="xerox-printers-description pt-2">
                      Xerox® ConnectKey® technology enabled Workplace Assistant
                      that can do it all
                    </p>
                    <ul className="xerox-printers-lists">
                      <li>Colour MFP with support for A3</li>
                      <li>Copy, print, scan, fax, email, cloud</li>
                      <li>
                        Tablet-like intuitive user interface lets you
                        personalize, simplify and speed through repetitive tasks
                      </li>
                      <li>Comprehensive security is built in</li>
                      <li>Ideal for small to mid size workgroups</li>
                      <li>
                        Small footprint: integrated office finisher saves space
                        without sacrificing features and functionality
                      </li>
                    </ul>
                    <div className="pt-4">
                      <div class="row">
                        <div class="col-md-3">
                          <p>Output</p>
                          <h3>Colour</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Paper size</p>
                          <h3>A3</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Up to</p>
                          <h3>30 ppm depending on model</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Up to</p>
                          <h3>7K pages/month depending on model</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="page-title pt-40 pos-rel bg_img"
            style={{ backgroundImage: `url(${Bg})` }}
          >
            <div className="container">
              <div className="page-title-wrap sd-title-wrap">
                <div class="row">
                  <div class="col-md-6">
                    <img class="xerox-image" src={XeroxImg2} alt="" />
                  </div>
                  <div class="col-md-6">
                    <h2 className="xerox-printers-title">
                      Xerox VersaLink B7100 Series Multifunction Printers
                    </h2>
                    <p className="xerox-printers-description pt-2">
                      Xerox® ConnectKey® technology enabled Workplace Assistant
                      that can do it all
                    </p>
                    <ul className="xerox-printers-lists">
                      <li>Black-and-white MFP with support for Tabloid</li>
                      <li>Copy, print, scan, fax, email, cloud</li>
                      <li>
                        Tablet-like intuitive user interface lets you
                        personalize, simplify and speed through repetitive tasks
                      </li>
                      <li>Comprehensive security is built in</li>
                      <li>Ideal for small to mid size workgroups</li>
                      <li>
                        Small footprint: integrated office finisher saves space
                        without sacrificing features and functionality
                      </li>
                    </ul>
                    <div className="pt-4">
                      <div class="row">
                        <div class="col-md-3">
                          <p>Output</p>
                          <h3>B&W</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Paper size</p>
                          <h3>A3</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Up to</p>
                          <h3>35 ppm depending on model</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Up to</p>
                          <h3>17K pages/month depending on model</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="sd-ser-content_wrap pb-50">
            <div className="container">
              <div className=" pr-0">
                <p className="sd-title px-60 fs-3 d-flex justify-content-center">
                  {/* {ServiceDetails?.getAQuoteText} */}
                </p>
                <h4 className="sd-title fs-3 d-flex justify-content-center view-more-btn">
                  <button
                    className="btn thm-btn thm-btn--aso thm-btn--aso_blue view-btn-size"
                    onClick={() => setModalOpen(true)}
                  >
                    Enquire Now
                  </button>
                </h4>
                <br />
                {/* Modal Form */}
                <AnimatePresence>
                  {isModalOpen && (
                    <motion.div
                      className="modal fade show d-block modal-backdrop-custom"
                      onClick={() => setModalOpen(false)}
                      variants={modalVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered modal-xs" // Changed from modal-xs to
                        modal-sm
                        onClick={(e) => e.stopPropagation()}
                        style={{ maxWidth: "500px" }}
                      >
                        <div className="modal-content modal-content-custom border-0 shadow-sm">
                          <div className="modal-header modal-header-custom py-4">
                            <h5 className="modal-title modal-title-custom fw-bold text-white text-center w-100">
                              Get Your Free Audit
                            </h5>
                            <button
                              type="button"
                              className="btn-close modal-close-custom"
                              onClick={() => setModalOpen(false)}
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body modal-body-custom p-30 ">
                            <form onSubmit={submitHandler}>
                              <div className="row g-4">
                                <div className="col-md-6">
                                  <label className="form-label fw-semibold text-gray-800 mb-2">
                                    Your Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={forms.name}
                                    className="form-control input-custom rounded-4 bg-white py-3 px-4 h-auto"
                                    placeholder="John Doe"
                                    required
                                    onChange={changeHandler}
                                    onFocus={(e) =>
                                      e.target.classList.add("input-focus")
                                    }
                                    onBlur={(e) =>
                                      e.target.classList.remove("input-focus")
                                    }
                                  />
                                  {validator.message(
                                    "name",
                                    forms.name,
                                    "required|alpha_space"
                                  )}
                                </div>
                                <div className="col-md-6">
                                  <label className="form-label fw-semibold text-gray-800 mb-2">
                                    Your Email{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    value={forms.email}
                                    className="form-control input-custom border-0 rounded-4 bg-white  py-3 px-4 h-auto"
                                    placeholder="example@domain.com"
                                    required
                                    onChange={changeHandler}
                                    onFocus={(e) =>
                                      e.target.classList.add("input-focus")
                                    }
                                    onBlur={(e) =>
                                      e.target.classList.remove("input-focus")
                                    }
                                  />
                                  {validator.message(
                                    "email",
                                    forms.email,
                                    "required|email"
                                  )}
                                </div>
                              </div>

                              <div className="mt-4">
                                <label className="form-label fw-semibold text-gray-800 mb-2">
                                  Your Phone Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="tel"
                                  value={forms.phone}
                                  className="form-control input-custom border-0 rounded-4 bg-white  py-3 px-4 h-auto"
                                  placeholder="+1 (123) 456-7890"
                                  required
                                  onChange={changeHandler}
                                  onFocus={(e) =>
                                    e.target.classList.add("input-focus")
                                  }
                                  onBlur={(e) =>
                                    e.target.classList.remove("input-focus")
                                  }
                                />
                                {validator.message(
                                  "phone",
                                  forms.phone,
                                  "required|phone"
                                )}
                              </div>

                              <div className="mt-4">
                                <label className="form-label fw-semibold text-gray-800 mb-2">
                                  Your Organization Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  value={forms.company}
                                  className="form-control input-custom border-0 rounded-4 bg-white  py-3 px-4 h-auto"
                                  placeholder="My Company"
                                  required
                                  onChange={changeHandler}
                                  onFocus={(e) =>
                                    e.target.classList.add("input-focus")
                                  }
                                  onBlur={(e) =>
                                    e.target.classList.remove("input-focus")
                                  }
                                />
                              </div>

                              <div className="d-flex justify-content-center">
                                <button
                                  type="submit"
                                  name="submit"
                                  disabled={isLoading}
                                  onMouseEnter={(e) =>
                                    e.target.classList.add("button-hover")
                                  }
                                  onMouseLeave={(e) =>
                                    e.target.classList.remove("button-hover")
                                  }
                                  className={
                                    isLoading
                                      ? "loading"
                                      : "btn button-custom w-auto mt-5 py-3 fw-bold thm-btn thm-btn--aso thm-btn--aso_blue"
                                  }
                                >
                                  {isLoading ? (
                                    <>
                                      <Spin
                                        indicator={<LoadingOutlined spin />}
                                      />
                                      <span style={{ marginLeft: "8px" }}>
                                        Submitting...
                                      </span>
                                    </>
                                  ) : (
                                    <>Send Message</>
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
          <section
            className="page-title pt-20 pos-rel bg_img"
            style={{ backgroundImage: `url(${Bg})` }}
          >
            <div className="container">
              <div className="page-title-wrap sd-title-wrap">
                <div className="row mt-none-30 ">
                  <div className="col-lg-9 mt-30">
                    <div className="page-title-box">
                      <h1 className="pb-5">Altalink</h1>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <img class="xerox-image" src={XeroxImg3} alt="" />
                  </div>
                  <div class="col-md-6">
                    <h2 className="xerox-printers-title">
                      Xerox AltaLink C8200 Series Colour Multifunction Printers
                    </h2>
                    <p className="xerox-printers-description pt-2">
                      Now work can get itself done.
                    </p>
                    <ul className="xerox-printers-lists">
                      <li>Copy, print, scan, fax, and email</li>
                      <li>
                        Streamline complex jobs with AI-assisted technology
                      </li>
                      <li>
                        Enjoy a tablet-like interface that simplifies and
                        accelerates repetitive work
                      </li>
                      <li>
                        Connect and print seamlessly via Wired, Wi-Fi®, Wi-Fi
                        Direct®, Bluetooth™, NFC, and Cloud
                      </li>
                      <li>
                        Right fit for busy offices. Customize your configuration
                        for simple to demanding applications.
                      </li>
                      <li>
                        Comprehensive security with simplified implementation is
                        built in
                      </li>
                      <li>
                        Experience superior color consistency and rendering,
                        perfect for critical office needs
                      </li>
                    </ul>
                    <div className="pt-4">
                      <div class="row">
                        <div class="col-md-3">
                          <p>Output</p>
                          <h3>Colour</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Paper size</p>
                          <h3>A3</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Up to</p>
                          <h3>70 ppm depending on model</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Up to</p>
                          <h3>40K pages/month</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="page-title pt-40 pos-rel bg_img"
            style={{ backgroundImage: `url(${Bg})` }}
          >
            <div className="container">
              <div className="page-title-wrap sd-title-wrap">
                <div class="row">
                  <div class="col-md-6">
                    <img class="xerox-image" src={XeroxImg4} alt="" />
                  </div>
                  <div class="col-md-6">
                    <h2 className="xerox-printers-title">
                      Xerox AltaLink B8200 Series Multifunction Printers
                    </h2>
                    <p className="xerox-printers-description pt-2">
                      Now work can get itself done.
                    </p>
                    <ul className="xerox-printers-lists">
                      <li>Copy, print, scan, fax, and email</li>
                      <li>
                        Streamline complex jobs with AI-assisted technology
                      </li>
                      <li>
                        Enjoy a tablet-like interface that simplifies and
                        accelerates repetitive work
                      </li>
                      <li>
                        Connect and print seamlessly via Wired, Wi-Fi®, Wi-Fi
                        Direct®, Bluetooth™, NFC, and Cloud
                      </li>
                      <li>
                        Right fit for busy offices. Customize your configuration
                        for simple to demanding applications.
                      </li>
                      <li>
                        Comprehensive security with simplified implementation is
                        built in
                      </li>
                    </ul>
                    <div className="pt-4">
                      <div class="row">
                        <div class="col-md-3">
                          <p>Output</p>
                          <h3>B&W</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Paper size</p>
                          <h3>A3</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Up to</p>
                          <h3>72 ppm depending on model</h3>
                        </div>
                        <div class="col-md-3">
                          <p>Up to</p>
                          <h3>100K pages/month</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="sd-ser-content_wrap pb-50">
            <div className="container">
              <div className=" pr-0">
                <p className="sd-title px-60 fs-3 d-flex justify-content-center">
                  {/* {ServiceDetails?.getAQuoteText} */}
                </p>
                <h4 className="sd-title fs-3 d-flex justify-content-center view-more-btn">
                  <button
                    className="btn thm-btn thm-btn--aso thm-btn--aso_blue view-btn-size"
                    onClick={() => setModalOpen(true)}
                  >
                    Enquire Now
                  </button>
                </h4>
                <br />
                {/* Modal Form */}
                <AnimatePresence>
                  {isModalOpen && (
                    <motion.div
                      className="modal fade show d-block modal-backdrop-custom"
                      onClick={() => setModalOpen(false)}
                      variants={modalVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered modal-xs" // Changed from modal-xs to
                        modal-sm
                        onClick={(e) => e.stopPropagation()}
                        style={{ maxWidth: "500px" }}
                      >
                        <div className="modal-content modal-content-custom border-0 shadow-sm">
                          <div className="modal-header modal-header-custom py-4">
                            <h5 className="modal-title modal-title-custom fw-bold text-white text-center w-100">
                              Get Your Free Audit
                            </h5>
                            <button
                              type="button"
                              className="btn-close modal-close-custom"
                              onClick={() => setModalOpen(false)}
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body modal-body-custom p-30 ">
                            <form>
                              <div className="row g-4">
                                <div className="col-md-6">
                                  <label className="form-label fw-semibold text-gray-800 mb-2">
                                    Your Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control input-custom rounded-4 bg-white py-3 px-4 h-auto"
                                    placeholder="John Doe"
                                    required
                                    onFocus={(e) =>
                                      e.target.classList.add("input-focus")
                                    }
                                    onBlur={(e) =>
                                      e.target.classList.remove("input-focus")
                                    }
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label className="form-label fw-semibold text-gray-800 mb-2">
                                    Your Email{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control input-custom border-0 rounded-4 bg-white  py-3 px-4 h-auto"
                                    placeholder="example@domain.com"
                                    required
                                    onFocus={(e) =>
                                      e.target.classList.add("input-focus")
                                    }
                                    onBlur={(e) =>
                                      e.target.classList.remove("input-focus")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="mt-4">
                                <label className="form-label fw-semibold text-gray-800 mb-2">
                                  Your Phone Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="tel"
                                  className="form-control input-custom border-0 rounded-4 bg-white  py-3 px-4 h-auto"
                                  placeholder="+1 (123) 456-7890"
                                  required
                                  onFocus={(e) =>
                                    e.target.classList.add("input-focus")
                                  }
                                  onBlur={(e) =>
                                    e.target.classList.remove("input-focus")
                                  }
                                />
                              </div>

                              <div className="mt-4">
                                <label className="form-label fw-semibold text-gray-800 mb-2">
                                  Your Organization Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control input-custom border-0 rounded-4 bg-white  py-3 px-4 h-auto"
                                  placeholder="My Company"
                                  required
                                  onFocus={(e) =>
                                    e.target.classList.add("input-focus")
                                  }
                                  onBlur={(e) =>
                                    e.target.classList.remove("input-focus")
                                  }
                                />
                              </div>

                              <div className="d-flex justify-content-center">
                                <button
                                  type="submit"
                                  className="btn button-custom w-auto mt-5 py-3 fw-bold thm-btn thm-btn--aso thm-btn--aso_blue"
                                  onMouseEnter={(e) =>
                                    e.target.classList.add("button-hover")
                                  }
                                  onMouseLeave={(e) =>
                                    e.target.classList.remove("button-hover")
                                  }
                                >
                                  Send Message
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </main>
        <CtaSection />
      </div>
      <Footer />
      <Whatsapp />
      <Scrollbar />
    </Fragment>
  );
};

export default XeroxPrinters;
