import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Fade } from "react-awesome-reveal";
import ModalVideo from "react-modal-video";
import cIcon1 from '../../images/icon/cp-icon.svg';
import cIcon2 from '../../images/icon/loc-icon.svg';
import cIcon3 from '../../images/icon/clock-icon.svg';

const jobListings = [
    {
        id: 1,
        title: "Business Development Executive",
        description: "We are seeking a results-driven and highly motivated Business Development Executive to join our dynamic team. As a key contributor to our growth strategy, the Business Development Executive will play a pivotal role in identifying new business opportunities, fostering client relationships, and driving revenue expansion.",
        location: "Bangalore",
        type: "Full time",
        slug: "enterprise-seo-consultant",
        categories: ["cat1"],
    },
];

// Modal animation variants
const modalVariants = {
    hidden: {
        y: "-100vh", // Start off-screen at the top
        opacity: 0,
    },
    visible: {
        y: 0, // Slide to center
        opacity: 1,
        transition: {
            type: "spring",
            stiffness: 100,
            damping: 20,
            duration: 0.5,
        },
    },
    exit: {
        y: "-100vh", // Slide back up
        opacity: 0,
        transition: {
            duration: 0.3,
        },
    },
};

const CareerTeam = () => {
    const [activeFilter, setActiveFilter] = useState("*");
    const [isOpen, setOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    const charLimit = 100;

    const SubmitHandler = (e) => {
        e.preventDefault();
    };

    const handleFilter = (category) => {
        setActiveFilter(category);
    };

    const openModal = (job) => {
        setSelectedJob(job);
    };

    const closeModal = () => {
        setSelectedJob(null);
    };

    const toggleReadMore = (jobId) => {
        setExpandedDescriptions((prev) => ({
            ...prev,
            [jobId]: !prev[jobId],
        }));
    };

    const filters = [
        { key: "*", label: "View All" },
        { key: "cat1", label: "Business Development" },
        { key: "cat2", label: "Sales" },
        { key: "cat3", label: "Marketing" },
        { key: "cat4", label: "Customer Services" },
        { key: "cat5", label: "Management" },
    ];

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <section className="team pt-130 pb-130">
            <div className="container">
                <div className="sec-title--two text-center mb-50">
                    <Fade direction='down' triggerOnce={false} duration={1000} delay={9}>
                        <div>
                            <div className="sub-title wow fadeInDown" data-wow-duration="600ms">
                                <img src={cIcon1} alt="icon" />
                                Open Positions
                            </div>
                        </div>
                    </Fade>
                    <Fade direction='up' triggerOnce={false} duration={1200} delay={9}>
                        <div>
                            <h2 className="title wow fadeInDown" data-wow-delay="150ms" data-wow-duration="600ms">
                                Join our team
                            </h2>
                        </div>
                    </Fade>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-12 text-center">
                        <div className="team-menu mb-50">
                            {filters.map((filter, index) => (
                                <button
                                    key={index}
                                    className={activeFilter === filter.key ? "active" : ""}
                                    onClick={() => handleFilter(filter.key)}
                                >
                                    {filter.label}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="cp-team-wrap grid">
                            <AnimatePresence>
                                {jobListings
                                    .filter(
                                        (job) => activeFilter === "*" || job.categories.includes(activeFilter)
                                    )
                                    .map((job) => {
                                        const isLongText = job.description && job.description.length > charLimit;
                                        const shortText = isLongText ? job.description.slice(0, charLimit) + "..." : job.description || "";
                                        const isExpanded = expandedDescriptions[job.id];

                                        return (
                                            <motion.div
                                                key={job.id}
                                                className={`cp-team-item grid-item ${job.categories.join(" ")}`}
                                                initial={{ opacity: 0, scale: 0.9 }}
                                                animate={{ opacity: 1, scale: 1 }}
                                                exit={{ opacity: 0, scale: 0.9 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <div className="xb-item--inner d-flex justify-content-between w-100">
                                                    <div className="xb-item--content">
                                                        <h3 className="xb-item--title">
                                                            <Link onClick={scrollToTop} to="/career-details">
                                                                {job.title}
                                                            </Link>
                                                        </h3>
                                                        <p className="p-2">
                                                            {isExpanded ? (job.description || "No description available.") : shortText}
                                                            {isLongText && (
                                                                <button 
                                                                    onClick={() => toggleReadMore(job.id)} 
                                                                    className="text-blue-500 underline mt-2 ms-2 bg-transparent border-0"
                                                                >
                                                                    {isExpanded ? "Read Less" : "Read More"}
                                                                </button>
                                                            )}
                                                        </p>
                                                        <div className="xb-item--holder ul_li">
                                                            <span className="xb-item--meta">
                                                                <img src={cIcon2} alt="location" />
                                                                {job.location}
                                                            </span>
                                                            <span className="xb-item--meta">
                                                                <img src={cIcon3} alt="time" />
                                                                {job.type}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="cp-team-btn">
                                                        <button
                                                            onClick={() => openModal(job)}
                                                            className="cp-btn"
                                                        >
                                                            Apply <i className="fal fa-arrow-right"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* Animated Modal */}
                                                <AnimatePresence>
                                                    {selectedJob && selectedJob.id === job.id && (
                                                        <motion.div
                                                            className="modal fade show d-block modal-backdrop-custom"
                                                            onClick={closeModal}
                                                            variants={modalVariants}
                                                            initial="hidden"
                                                            animate="visible"
                                                            exit="exit"
                                                        >
                                                            <div
                                                                className="modal-dialog modal-dialog-centered modal-lg"
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <div className="modal-content modal-content-custom border-0 shadow-sm">
                                                                    <div className="modal-header modal-header-custom py-4 sticky-top">
                                                                        <h5 className="modal-title modal-title-custom fw-bold text-white text-center w-100">
                                                                            Apply for {job.title}
                                                                        </h5>
                                                                        <button 
                                                                            type="button" 
                                                                            className="btn-close modal-close-custom"
                                                                            onClick={closeModal}
                                                                            aria-label="Close"
                                                                        ></button>
                                                                    </div>
                                                                    <div className="modal-body modal-body-custom p-0 border-0">
                                                                        <div className="cs-contact-wrap cs-contact-form p-5 pb-3 border-0 pt-3 cd-contact-form bg-transparent">
                                                                            <h2 className="xb-title text-center">Apply for this job</h2>
                                                                            <p className="xb-content">Replica Xerography doesn’t accept unsolicited resumes from recruiters or employment agencies.</p>
                                                                            <form onSubmit={SubmitHandler} className="contact-form">
                                                                                <div className="row">
                                                                                    <div className="col-lg-6">
                                                                                        <div className="input-field mt-1 mb-2">
                                                                                            <label htmlFor="text1" className="form-label fw-medium text-gray-800">
                                                                                                Name <span className="text-danger">*</span>
                                                                                            </label>
                                                                                            <div className="input-box">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    name="text"
                                                                                                    id="text1"
                                                                                                    placeholder="Enter your Name"
                                                                                                    className="form-control input-custom border-0 rounded-4 bg-white py-3 px-4 h-auto"
                                                                                                    required
                                                                                                    onFocus={(e) => e.target.classList.add('input-focus')}
                                                                                                    onBlur={(e) => e.target.classList.remove('input-focus')}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6">
                                                                                        <div className="input-field mt-1 mb-2">
                                                                                            <label htmlFor="text2" className="form-label fw-medium text-gray-800 ">
                                                                                                Email <span className="text-danger">*</span>
                                                                                            </label>
                                                                                            <div className="input-box">
                                                                                                <input
                                                                                                    type="email"
                                                                                                    name="gmail"
                                                                                                    id="text2"
                                                                                                    className="form-control input-custom border-0 rounded-4 bg-white py-3 px-4 h-auto"
                                                                                                    placeholder="Enter your Email"
                                                                                                    required
                                                                                                    onFocus={(e) => e.target.classList.add('input-focus')}
                                                                                                    onBlur={(e) => e.target.classList.remove('input-focus')}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6">
                                                                                        <div className="input-field mt-1 mb-2">
                                                                                            <label htmlFor="text3" className="form-label fw-medium text-gray-800 ">
                                                                                                Phone <span className="text-danger">*</span>
                                                                                            </label>
                                                                                            <div className="input-box">
                                                                                                <input
                                                                                                    type="tel"
                                                                                                    name="number"
                                                                                                    id="text3"
                                                                                                    placeholder="Enter your Phone"
                                                                                                    className="form-control input-custom border-0 rounded-4 bg-white py-3 px-4 h-auto"
                                                                                                    onFocus={(e) => e.target.classList.add('input-focus')}
                                                                                                    onBlur={(e) => e.target.classList.remove('input-focus')}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6">
                                                                                        <div className="input-field mt-1 mb-2">
                                                                                            <label htmlFor="text4" className="form-label fw-medium text-gray-800 ">
                                                                                                Location <span className="text-danger">*</span>
                                                                                            </label>
                                                                                            <div className="input-box">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    name="text"
                                                                                                    id="text4"
                                                                                                    placeholder="Enter your Location"
                                                                                                    className="form-control input-custom border-0 rounded-4 bg-white py-3 px-4 h-auto"
                                                                                                    onFocus={(e) => e.target.classList.add('input-focus')}
                                                                                                    onBlur={(e) => e.target.classList.remove('input-focus')}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-12">
                                                                                        <div className="input-field mt-1 mb-2 text-field">
                                                                                            <label htmlFor="text5" className="form-label fw-medium text-gray-800 ">
                                                                                                Message
                                                                                            </label>
                                                                                            <div className="input-box">
                                                                                                <textarea
                                                                                                    name="massage"
                                                                                                    id="text5"
                                                                                                    className="form-control input-custom border-0 rounded-4 bg-white py-3 px-4 h-auto"
                                                                                                    placeholder="Enter your Message"
                                                                                                    rows="4"
                                                                                                    onFocus={(e) => e.target.classList.add('input-focus')}
                                                                                                    onBlur={(e) => e.target.classList.remove('input-focus')}
                                                                                                ></textarea>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-12">
                                                                                        <div className="input-field mt-1 mb-1">
                                                                                            <label htmlFor="file" className="form-label fw-medium text-gray-800 ">
                                                                                                Upload your resume / CV <span className="text-danger">*</span>
                                                                                            </label>
                                                                                            <input
                                                                                                id="file"
                                                                                                type="file"
                                                                                                className="form-control input-custom border-0 py-3 px-4 h-auto"
                                                                                                required
                                                                                                onFocus={(e) => e.target.classList.add('input-focus')}
                                                                                                onBlur={(e) => e.target.classList.remove('input-focus')}
                                                                                            />
                                                                                            <p className="xb-item--content text-muted mt-2">
                                                                                                <span>Accepted file types:</span> pdf, doc, jpg, Max. file size: 15 MB.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="cp-contact-bottom ul_li_between mt-3">
                                                                                    <div className="xb-item--left mt-20"></div>
                                                                                    <div className="cp-det-btn mt-0 w-100 d-flex justify-content-center">
                                                                                        <button
                                                                                            type="submit"
                                                                                            className="cp-btn button-custom py-3 fw-bold"
                                                                                            onMouseEnter={(e) => e.target.classList.add('button-hover')}
                                                                                            onMouseLeave={(e) => e.target.classList.remove('button-hover')}
                                                                                        >
                                                                                            Submit now <i className="fal fa-arrow-right"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </motion.div>
                                        );
                                    })}
                            </AnimatePresence>
                        </div>
                    </div>
                </div>
            </div>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="7e90gBu4pas"
                onClose={() => setOpen(false)}
            />
        </section>
    );
};

export default CareerTeam;